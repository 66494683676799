import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'filterProfs',
})
export class FilterProfsPipe implements PipeTransform {
    transform(items: any[], filter: Object): any {
        if (!items || !filter) {
            return items;
        }

        if (filter['id'] === 0) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        // return items.filter(item => item.matiere.find(filter['value']) !== -1);
        return items.filter(item => item.subject_id == filter['id'] ) ;
    }
}