import {Component, ElementRef, Input, OnInit, Renderer, Renderer2, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ScolarityEntity} from "../../datas/scolarity-entity";
import {TableDataSource} from "angular4-material-table";
import {UserService} from "../../_services/user.service";
import {MatDialog, MatSnackBar} from "@angular/material";
import {AddControlDialogueComponent} from "../add-control-dialogue/add-control-dialogue.component";
import {Observable} from "rxjs/Rx";
import {EditUploadControlDialogueComponent} from "../edit-upload-control-dialogue/edit-upload-control-dialogue.component";
import {AddBulletinDialogueComponent} from "../add-bulletin-dialogue/add-bulletin-dialogue.component";
import {forEach} from "@angular/router/src/utils/collection";
import {JwtHelperService} from "@auth0/angular-jwt";
import {toInteger} from "@ng-bootstrap/ng-bootstrap/util/util";
import {AddNewControlDialogueComponent} from "../add-new-control-dialogue/add-new-control-dialogue.component";
import {AlertsComponent} from "../alerts/alerts.component";
import {ControlEntity} from "../../datas/control-entity";
import {MoyenneEntity} from "../../datas/moyenne-entity";




@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
        trigger('detailExpand1', [
            state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    providers: [
        UserService
    ]
})
export class NotesComponent implements OnInit {

    @Input() idapprenant: number;
    @Input() year: string = '0';
    @ViewChild('moytxt') moytxt: ElementRef;

    notes = [];
    subjects = [];

  datas: any = [];
  controls: any = [];
  uploads: any = [];
  intervenants:any = null;
  teacher;
  trimester : number;
  trimesters:any;
  juste;
  user;
    moys;
    showNotesLoader = true;

    notex = {
        [1]: '...',
        [2]: '...',
        [3]: '...',
    };
  selectedItem = 0;
  role;role_acces;
  annee_en_cours;next;prev;now;

  constructor(private userService: UserService,   public snackBar: MatSnackBar, public dialog: MatDialog) { }


  ngOnInit() {
        this.getLogedUser();
        //this.setHide(999);
        this.getYear();

  }

    getYear(){
        this.userService.getThisYear().subscribe( res => {
            this.annee_en_cours = res[0];
            this.showYear(this.year,0);
        });
    }

    isIntervenant(role){

        if(role == 'IS_INTERVENANT'){
            this.role_acces = true;
        }else{
            this.role_acces = false;
        }
    }
    getLogedUser(){
        if (localStorage.getItem('currentUser')) {
            const jwthelper = new JwtHelperService();
            let current_user = jwthelper.decodeToken(localStorage.getItem('currentUser'));
            this.isIntervenant(localStorage.getItem('roles'));
            if(this.idapprenant != null){
                this.user = this.idapprenant;
            }else{
                this.user = 0;
                this.idapprenant = 0;
            }
            //this.getIntervenants(this.user);
            this.getSubjects();



        }else{
            console.log('je crois que vous n\'etes pas connecté , merci de checker, sinon contactez l\'administration')
        }
    }

    getSubjects(){
        this.userService.getAllSubjects().subscribe( res => {
            this.subjects = res;
            this.getAllNotesApprenant(this.user,this.year);

            this.getMoys(this.user);
        });
    }
    setYearNotes(n){

      if(n == 0){
            if(parseInt(this.year) == 0){
                this.showYear(this.year,n);
               return;
            }else{
                this.year = (parseInt(this.year) - 1).toString();

                this.showYear(this.year,n);
            }
      }else if(n == 1){
          if(parseInt(this.year) == 0){
              this.year = ((parseInt(this.year) + 1)).toString();
              this.showYear(this.year,n);
              //return;
          }else{
              this.year = (parseInt(this.year) + 1).toString();
              this.showYear(this.year,n);
          }
      }

      this.getAllNotesApprenant(this.user,this.year);
    }

    showYear(n,a){
        let t = parseInt((this.annee_en_cours.libelle.split('-')[0]).substr(2,4));
        let t2 = parseInt((this.annee_en_cours.libelle.split('-')[1]).substr(2,4));

        this.now = t +'/'+ t2;
        if(a == 0){
            if( parseInt(this.year) <= 0) {
                this.now = t +'/'+ t2;
                console.log('noy1' + this.now);
            }else{
                this.now = (t - parseInt(n)) +'/'+ (t2 - parseInt(n));
                console.log('noy2' + this.now);
            }

        }else{
            this.now = (t - parseInt(n)) +'/'+ (t2 - parseInt(n));
            console.log('nox' + this.now);
        }
        //this.now = (t + parseInt(n)) +'/'+ (t2 + parseInt(n));


    }


    showControl(n){

        const dialogRef = this.dialog.open(AddControlDialogueComponent, {
            width: '50%',
            data: {id: n}

        });

        dialogRef.afterClosed().subscribe(result => {
            this.getAllNotesApprenant(this.user,this.year);
        });
    }
    deleteControl(id){
      //console.log(id); return;
        this.userService.deleteControl(id).subscribe( res => {
            this.snackBar.openFromComponent(AlertsComponent,{
                duration: 1000,
                panelClass: ['alerts-snackbar-seccus'],
                data:res
            });
            this.getAllNotesApprenant(this.user,this.year);
        });

    }

    getUser(){

        this.userService.getProfileX(this.user).subscribe(

            data => {
                this.teacher = data[0].id;

            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            });
    }

    navigateToLink(link){
        window.open(

            //'http://api.az-educ.fr/uploads/brochures/'+link,
            'http://api.az-educ.fr/uploads/brochures/'+link,
            '_blank'
        );

    }
    navigateToLinUpload(link){
        window.open(
            //'http://api.az-educ.fr/uploads/brochures/'+link,
            'http://api.az-educ.fr/uploads/brochures/'+link,
            '_blank'
        );

    }

    getIntervenants(id:number){
      //console.log(id);
        this.userService.getIntervenantsByApprenant(id).subscribe(
            data => {

                this.getUser();
                this.intervenants = data;
                this.showNotesLoader = false;

            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            });
    }

    getAllNotesApprenant(n1,n2){
        this.userService.getAllNotesApprenant(n1,n2).subscribe(
            data => {

                //this.getUser();
                this.notes = data;
                this.showNotesLoader = false; return;

            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            });
    }
    setMoy(sub,tri,moy){

        //console.log(control);return;
        // TODO: send data de backend api
        //console.log(this.idapprenant); return;
        let moyenne = new MoyenneEntity();
        moyenne.apprenant = this.idapprenant;
        moyenne.subject = sub;
        moyenne.trimester = tri;
        moyenne.moy = parseFloat(moy.value);

        this.userService.setMoy(moyenne).subscribe(res => {
            this.snackBar.openFromComponent(AlertsComponent,{
                duration: 1000,
                panelClass: ['alerts-snackbar-seccus'],
                data:res
            });

        });
    }
    getMoy(sub,tri){
        let t = 0;
      if(this.moys){

          this.moys.forEach(function (value) {

              if(parseInt(value.trimester) == parseInt(tri) && (value.subject) == (sub)){
                  t =  value.moy;
                  return ;
              }

          });
          return t;
      }else{
          return t;
      }



    }
    getMoys(user){

        this.userService.getMoys(user,this.year).subscribe(
            data => {
                this.moys = data;
            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            });
    }
    newControl(tri,sub, int): void {
        const dialogRef = this.dialog.open(AddControlDialogueComponent, {
            width: '50%',
            data: {trimester: tri, apprenant: this.idapprenant, intervenant: int, subject: sub}

        });

        dialogRef.afterClosed().subscribe(result => {
            //this.getIntervenants(this.user);
            //this.getNotes(tri,sub,this.idapprenant);
            //this.getAllNotesApprenant(this.user);
            //this.setHide(0);


        });
    }

    addNewControl(): void {

        const dialogRef = this.dialog.open(AddNewControlDialogueComponent, {
            width: '50%',
            data: { apprenant: this.idapprenant, intervenant: 1}

        });

        dialogRef.afterClosed().subscribe(result => {
            this.getAllNotesApprenant(this.user,this.year);

        });
    }
    addNewControlByMat(trm,mat): void {

        const dialogRef = this.dialog.open(AddNewControlDialogueComponent, {
            width: '50%',
            data: { apprenant: this.idapprenant, intervenant: 1, trm: trm, mat: mat}

        });

        dialogRef.afterClosed().subscribe(result => {
            this.getAllNotesApprenant(this.user,this.year);

        });
    }



    editControlUpload(id){
        const dialogRef1 = this.dialog.open(EditUploadControlDialogueComponent, {
            width: '50%',
            data: {id: id}
        });

        dialogRef1.afterClosed().subscribe(result => {
            //this.getNotes();
            this.getIntervenants(this.user);
            this.setHide(0);

        });
    }

    newBulletin(id,int): void{

        const dialogRef = this.dialog.open(AddBulletinDialogueComponent, {
            width: '50%',
            data: {trimester: id, apprenant: this.idapprenant, intervenant: int}

        });

        dialogRef.afterClosed().subscribe(result => {
            //this.getNotes();

        });
    }

    getDatas(d1,d2,d3){

      this.getNotes(d1,d2,this.idapprenant);
      this.getBulletins(this.idapprenant, d1);

    }

    setHide(id){
      this.selectedItem = 999;
    }

    getNotes(d1,d2,d3){
        //this.setHide(999);
        this.userService.getNotes(d1,d2,d3).subscribe(
            data => {

                this.controls = data;

                let nts1 = 0;
                let nts2 = 0;
                let nts3 = 0;
                let countnts1 = 0;
                let countnts2 = 0;
                let countnts3 = 0;
                let cnx1 = '...';
                let cnx2 = '...';
                let cnx3 = '...';
                for(let mesnotes of this.controls){
                    if(mesnotes.trimester == 1){
                        nts1 +=  parseInt(mesnotes.note);
                        countnts1 += 1;
                    }else if(mesnotes.trimester == 2){
                        nts2 +=  parseInt(mesnotes.note);
                        countnts2 += + 1;
                    }else if(mesnotes.trimester == 3){
                        nts3 += parseInt(mesnotes.note);
                        countnts3 += 1;

                    }
                }
                if(!isNaN(nts1/countnts1)){    cnx1 =  '' + nts1 / countnts1; }
                if(!isNaN(nts2/countnts2)){    cnx2 =  '' + nts2 / countnts2; }
                if(!isNaN(nts3/countnts3)){    cnx3 =  '' + nts3 / countnts3; }
                this.notex = {
                    [1]: cnx1 ,
                    [2]: cnx2,
                    [3]: cnx3,
                };

                this.selectedItem = d1;
            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            });
    }


    getBulletins(apr,int){
        this.userService.getBulletins(apr,int).subscribe(
            data => {
                this.uploads = data;

            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            });
    }

}









