import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {UserService} from "../../_services/user.service";
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from "@angular/material";
import {FormBuilder, FormGroup, Validators,FormControl} from "@angular/forms";
import {Master, Subject} from "../pedagogy/pedagogy.component";
import {PedagogyEntity} from "../../datas/pedagogy-entity";
import {AlertsComponent} from "../alerts/alerts.component";

export interface DialogData {
    subjects: Subject;
    masters: Master;
    user: string;
}

@Component({
  selector: 'app-add-pedagogy-dialogue',
  templateUrl: './add-pedagogy-dialogue.component.html',
  styleUrls: ['./add-pedagogy-dialogue.component.scss'],
    providers: [
        UserService
    ]
})
export class AddPedagogyDialogueComponent implements OnInit {
    subjects = this.data.subjects;
    masters = this.data.masters;
    user = this.data.user;
    selectedSubject;
    selectedMaster;

    @Output() pedagogyAdded: EventEmitter<any> = new EventEmitter();

    //newPedagogy: FormGroup;
    newPedagogy = new FormGroup ({
        date_suivi: new FormControl(),
        chapter: new FormControl(),
        htp: new FormControl(),
        hma: new FormControl(),
        master: new FormControl(),
        subject: new FormControl(),
        to_work_on: new FormControl(),
        comment: new FormControl(),
    });
  constructor(
      public dialogRef: MatDialogRef<AddPedagogyDialogueComponent>,
      @Inject(MAT_DIALOG_DATA) private data: DialogData,
      private formBuilder: FormBuilder,
      private userService: UserService,
      public snackBar: MatSnackBar
  ) {

  }

  ngOnInit() {
     this.newPedagogy = this.formBuilder.group({
          date_suivi: ['', Validators.required],
          chapter: ['', Validators.required],
          htp: ['', Validators.required],
          hma: ['', Validators.required],
          master: ['', Validators.required],
          subject: ['', Validators.required],
          to_work_on: ['', Validators.required],
          comment: ['', Validators.required],
      });


  }
    onSubmit(){

        let p = this.newPedagogy.value;
        let pedagogy = new PedagogyEntity();
        pedagogy.date_suivi = p.date_suivi;
        pedagogy.chapter = p.chapter;
        pedagogy.subject = p.subject;
        pedagogy.master = p.master;
        pedagogy.comment = p.comment;
        pedagogy.to_work_on = p.to_work_on;
        pedagogy.htp = p.htp;
        pedagogy.hma = p.hma;

        // TODO: send data de backend api
        this.userService.newSuivi(this.user, pedagogy).subscribe(res => {
            this.snackBar.openFromComponent(AlertsComponent,{
                duration: 1000,
                panelClass: ['alerts-snackbar-seccus'],
                data:res
            });
            this.pedagogyAdded.emit(pedagogy);
            this.dialogRef.close(pedagogy);
        });
    }


    onSubjectChange(event){
      this.selectedSubject = event.value;
    }
    onMasterChange(event){
        this.selectedMaster = event.value;
    }
}
