import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import {JwtHelperService} from "@auth0/angular-jwt";
import {AuthenticationService} from "../_services/authentication.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(public auth: AuthenticationService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const jwthelper = new JwtHelperService();
        //let currentUser = jwthelper1.decodeToken(localStorage.getItem('currentUser'));
        let tokken = JSON.parse(localStorage.getItem('currentUser'));

        let currentUser1 = jwthelper.decodeToken(localStorage.getItem('currentUser'));
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {

            request = request.clone({

                setHeaders: {
                    'Authorization': `Bearer ${this.auth.getToken()}`
                },
                /*headers: new HttpHeaders({
                    'Authorization':  `Bearer ${localStorage.getItem('token')}`,
                }),*/
               // headers: request.headers.set('token',localStorage.getItem('token'))
            });
        }

        return next.handle(request);
    }
}