import { Component, OnInit } from '@angular/core';
import {MatBottomSheet} from "@angular/material";

@Component({
  selector: 'app-rapports',
  templateUrl: './rapports.component.html',
  styleUrls: ['./rapports.component.scss']
})
export class RapportsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }



}
