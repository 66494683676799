export class ScolarityEntity {
    public school_year: school_year;
    public level: Level;
    public speciality: string;
    public redoubling: boolean;
    public establishment: string;
    public establishment_city: string;
}

export class YearEntity {
    public id: string;
    public label: string;
    public order: string
}


export class Level {
    constructor(
    public id: number,
    public label: string,
    public order: number){}
}

export class school_year {
    id: number;
    label: string;
    order: string
}