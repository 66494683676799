export class ControlEntity {
    bulletin?: number;
    date_control: Date;
    id: number;
    intervenant: number;
    note: number;
    subject: Object;
    trimester: Object;
    uploads: number;
    apprenant: number;
    file?: any;
}
