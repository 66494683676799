import {Component, OnInit, ElementRef, Renderer2} from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { Router } from '@angular/router';
import {JwtHelperService} from "@auth0/angular-jwt";
import {UserService} from "../../_services/user.service";
import {Observable} from "rxjs/Rx";
declare const $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  providers:[UserService]
})
export class NavbarComponent implements OnInit {
    private listTitles: any[];
    location: Location;
      mobile_menu_visible: any = 0;
      current_user:string;
    private toggleButton: any;
    private sidebarVisible: boolean;
    unreaded_messages;
    notifs;nn;

    constructor(location: Location,  private element: ElementRef, private router: Router, private userService: UserService, private render:Renderer2 ) {
      this.location = location;
          this.sidebarVisible = false;
    }

     link: string;
     check_number: number;

    ngOnInit(){
      this.listTitles = ROUTES.filter(listTitle => listTitle);
      const navbar: HTMLElement = this.element.nativeElement;
      this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
      this.router.events.subscribe((event) => {
        this.sidebarClose();
         var $layer: any = document.getElementsByClassName('close-layer')[0];
         if ($layer) {
           $layer.remove();
           this.mobile_menu_visible = 0;
         }
     });
      this.getLogedUser();


    }

    isMobileMenuNav() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    getLogedUser(){
        if (localStorage.getItem('currentUser')) {
            const jwthelper = new JwtHelperService();
            let current_user = jwthelper.decodeToken(localStorage.getItem('currentUser'));
            let rol = localStorage.getItem('roles');
            if( rol == 'IS_INTERVENANT'){
                this.link = 'Intervenant';
                this.checkEmails(1);
            }else{
                this.link = 'Apprenant';
                this.checkEmails(0);
                this.checkNotifs();
            }

            this.getProfile()


        }else{
            console.log('je crois que vous n\'etes pas connecté , merci de checker, sinon contactez l\'administration')
        }
    }
    checkEmails(n){
        this.unreaded_messages = Observable.interval(8000).startWith(0)
            .switchMap(() => this.userService.checkEmails(n)).map((responseData) => {
                let rol = localStorage.getItem('roles');
                if( rol == 'IS_INTERVENANT'){
                    this.check_number = 0;
                }else{
                    this.check_number = 1;
                }
                return responseData;
            });
        //console.log(this.unreaded_messages);
    }

    checkNotifs(){
        this.notifs = Observable.interval(8000).startWith(0)
            .switchMap(() => this.userService.checkNotifs()).map((responseData) => {
                //console.log(responseData);
                    this.nn = responseData;
                    return responseData;
            });

    }

    getProfile(){
        this.userService.getProfileUser().subscribe(
            data => {
                //console.log(data);

                this.current_user = (data[0].firstName).substr(0,1) +'. '+data[0].lastName;

            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            });
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);

        body.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];

        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
        const body = document.getElementsByTagName('body')[0];

        if (this.mobile_menu_visible == 1) {
            // $('html').removeClass('nav-open');
            body.classList.remove('nav-open');
            if ($layer) {
                $layer.remove();
            }
            setTimeout(function() {
                $toggle.classList.remove('toggled');
            }, 400);

            this.mobile_menu_visible = 0;
        } else {
            setTimeout(function() {
                $toggle.classList.add('toggled');
            }, 430);

            var $layer = document.createElement('div');
            $layer.setAttribute('class', 'close-layer');


            if (body.querySelectorAll('.main-panel')) {
                document.getElementsByClassName('main-panel')[0].appendChild($layer);
            }else if (body.classList.contains('off-canvas-sidebar')) {
                document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
            }

            setTimeout(function() {
                $layer.classList.add('visible');
            }, 100);

            $layer.onclick = function() { //asign a function
              body.classList.remove('nav-open');
              this.mobile_menu_visible = 0;
              $layer.classList.remove('visible');
              setTimeout(function() {
                  $layer.remove();
                  $toggle.classList.remove('toggled');
              }, 400);
            }.bind(this);

            body.classList.add('nav-open');
            this.mobile_menu_visible = 1;

        }
    };

    getTitle(){
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 2 );
      }
      titlee = titlee.split('/').pop();

      for(var item = 0; item < this.listTitles.length; item++){
          if(this.listTitles[item].path === titlee){
              return this.listTitles[item].title;
          }
      }
        if ($(window).width() > 991) {
            return true;
        }else{
            return false;
        }

    }

    //closeNotification(ev,l){
    closeNotification(ev,n){

        this.userService.setAsReaded(ev,n).subscribe(
            data => {
                let target  = this.element.nativeElement.querySelector('.show');
                this.render.removeClass(target,'show');
            }
        );
    }
    closeNotificationSuivi(id){
        this.userService.setNotifAsReader(id).subscribe(
            data => {
                let target  = this.element.nativeElement.querySelector('.show');
                this.render.removeClass(target,'show');
            }
        )
    };
    ngAfterViewInit(){

    }
}
