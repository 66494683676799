import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidatorService } from 'angular4-material-table';

@Injectable()
export class ScolarityValidatorService implements ValidatorService {
    getRowValidator(): FormGroup {
        return new FormGroup({
            'school_year': new FormControl( Validators.required),
            'level': new FormControl(),
            'speciality': new FormControl(),
            'redoubling': new FormControl(),
            'establishment': new FormControl(),
            'establishment_city': new FormControl(),
            'schoolProject': new FormControl(),
        });
    }
}