import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import {HomeComponent} from './home/home.component';
import {ApprenantComponent} from './apprenant.component';
import {SuiviComponent} from './suivi/suivi.component';
import {ProfsComponent} from './profs/profs.component';
import {PlanningComponent} from './planning/planning.component';
import {ProfilComponent} from './profil/profil.component';
import {InboxComponent} from './inbox/inbox.component';
import {ContactComponent} from './contact/contact.component';
import {AuthGuard} from "../_guards/auth.guard";
import {FactureComponent} from "./facture/facture.component";
import {FactureDetailsComponent} from "./facture-details/facture-details.component";





const routes: Routes = [{
    path: 'apprenant',
    component: ApprenantComponent,
    canActivate: [AuthGuard],
    data: {
        expectedRole: 'IS_APPRENANT'
    },
    children: [{
        path: '',
        component: HomeComponent,
    }, {
        path: 'suivi',
        component: SuiviComponent,
    }, {
        path: 'professeurs',
        component: ProfsComponent,
    }, {
        path: 'planning',
        component: PlanningComponent,
    }, {
        path: 'profil',
        component: ProfilComponent,
    }, {
        path: 'conversations',
        component: InboxComponent,
    }, {
        path: 'facture',
        component: FactureComponent,

    },{
        path: 'facture/:id',
        component: FactureDetailsComponent
    }, {
        path: 'contact',
        component: ContactComponent
    }],
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ApprenantRoutingModule {
}
