import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from "./_services/user.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
    providers: [UserService]
})

export class AppComponent implements OnInit {
  private link: String = '';

  constructor(private router: Router) {
    this.link = this.router.url;
  };
    ngOnInit() {
        this.redirectByrole();
    }

    redirectByrole(){
        let role = localStorage.getItem('roles');
        if(role == 'IS_INTERVENANT'){
            this.router.navigate(['/intervenant']);
        }else if(role == 'IS_APPRENANT'){
            this.router.navigate(['/apprenant']);
        }
    }

}
