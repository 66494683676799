import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserService} from "../../_services/user.service";
import {TableDataSource, ValidatorService} from "angular4-material-table";
import {ScolarityEntity} from "../../datas/scolarity-entity";
import {CommandeEntity} from "../../datas/commande-entity";
import {ScolarityValidatorService} from "../../shared/scolarity/scolarity-validator.service";
import {CommandeValidatorService} from "./commande-validator.service";

@Component({
  selector: 'app-mes-commandes',
  templateUrl: './mes-commandes.component.html',
  styleUrls: ['./mes-commandes.component.scss'],
    providers: [
        {provide: ValidatorService, useClass: CommandeValidatorService },
        UserService
    ],
})
export class MesCommandesComponent implements OnInit {

    @Input() commandeList;
    @Output() commandeListChange = new EventEmitter<any>();
    displayedColumns = ['date_cmd'  , 'taux_conso', 'limite_conso', 'year_cmd', 'libelle' , 'qte', 'factured_hours' ,  'reduction_heures' , 'reduction_montant'  , 'totale' ];
    dataSource: TableDataSource<CommandeEntity>;

  constructor(private userService: UserService , private commandeValidator: ValidatorService,) { }

  ngOnInit() {
    this.getCommandes();
  }

    cmdTimeStatmpToDate(id){
      if(id != null && id != ''){
          let date = new Date(id *1000);
          let test = date.toLocaleDateString();

          return test;
      }else{
          return '';
      }

    }

  getCommandes(){
      this.userService.getCmds().subscribe(
          data => {
             //console.log(data);
              //this.displayedColumns = ['id' , 'date_cmd' , 'factured_hours' , 'libelle' , 'year_cmd' , 'nfacture' , 'prix' , 'reduction_heures' , 'reduction_montant' , 'volume' , 'totale' , 'taux_conso'];
              this.displayedColumns = ['date_cmd' , 'taux_conso', 'limite_conso', 'year_cmd' , 'libelle', 'qte', 'factured_hours' ,  'reduction_heures' , 'reduction_montant'  ,'totale' ];
             // this.dataSource = TableDataSource<CommandeEntity>;
             //return;

              this.commandeList = (data);

              this.dataSource = new TableDataSource<CommandeEntity>(this.commandeList,   CommandeEntity , this.commandeValidator);
              this.dataSource.datasourceSubject.subscribe(commandeList => this.commandeListChange.emit(commandeList));
              //console.log(this.dataSource);

          },
          err => {
              if (err.error instanceof Error) {
                  console.log("Client-side error occured.");
              } else {
                  console.log("Server-side error occured.");
              }
          });
  }

}
