import {Component, EventEmitter, Input,  OnInit, Output} from '@angular/core';
import {TableDataSource, ValidatorService} from 'angular4-material-table';
import {ScolarityValidatorService} from './scolarity-validator.service';
import {ScolarityEntity} from '../../datas/scolarity-entity';
import {UserService} from "../../_services/user.service";
import {AlertsComponent} from "../alerts/alerts.component";
import {JwtHelperService} from "@auth0/angular-jwt";
import {MatDialog, MatSnackBar} from "@angular/material";
import {AddPedagogyDialogueComponent} from "../add-pedagogy-dialogue/add-pedagogy-dialogue.component";
import {AddScolarityDialogueComponent} from "../add-scolarity-dialogue/add-scolarity-dialogue.component";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";




@Component({
  selector: 'app-scolarity',
    providers: [
        {provide: ValidatorService, useClass: ScolarityValidatorService },
        UserService
    ],
  templateUrl: './scolarity.component.html',
  styleUrls: ['./scolarity.component.scss'],

})
export class ScolarityComponent implements OnInit {

    @Input() idapprenant: number;

    displayedColumns = ['school_year', 'level', 'speciality', 'redoubling', 'establishment', 'establishment_city', 'actionsColumn'];
    dataSource: TableDataSource<ScolarityEntity>;
    user;

    showScolarityLoader = true;

    years =  [
        /*{id: 1, label: '2011/2012'},
        {id: 2, label: '2012/2013'},
        {id: 3, label: '2013/2014'},
        {id: 4, label: '2014/2015'},
        {id: 5, label: '2015/2016'},
        {id: 6, label: '2016/2017'},
        {id: 7, label: '2017/2018'},
        {id: 8, label: '2018/2019'},
        {id: 9, label: '2019/2020'},
        {id: 10, label: '2020/2021'}*/
    ];

    //years =  [ ];
    role_acces;
    levels =  [
        {id: 1, label: 'Primaire - CP'},
        {id: 2, label: 'Primaire - CE1'},
        {id: 6, label: 'Primaire - CM1'},
        {id: 7, label: 'Primaire - CM2'},
        {id: 8, label: 'Primaire - CE2'},
        {id: 9, label: 'Collège - 6 ème'},
        {id: 10,label:  'Collège - 5 ème'},
        {id: 11,label:  'Collège - 4 ème'},
        {id: 12,label:  'Collège - 3 ème'},
        {id: 13,label:  'Lycée - 2nde'},
        {id: 14,label:  'Lycée - 1ère'},
        {id: 15,label:  'Lycée Terminale'},
        {id: 21,label:  'Licence 1'},
        {id: 22,label:  'Licence 2 '},
        {id: 23,label:  'Licence 3 '},
        {id: 24,label:  'Master 1'},
        {id: 25,label:  'Master 2'},
        {id: 26,label:  'Prépa 1'},
        {id: 28,label:  'Prépa 2'},
        {id: 29,label:  'BTS 1 '},
        {id: 30,label:  'BTS 2'},
        {id: 31,label:  'IUT 1'},
        {id: 32,label:  'IUT 2 '},
        {id: 33,label:  'Adulte'},
    ];
     @Input() scolarityList;
     @Output() scolarityListChange = new EventEmitter<any>();

    constructor(private scolarityValidator: ValidatorService,
                private userService: UserService,
                public snackBar: MatSnackBar,
                public dialog: MatDialog
                ) { }

    ngOnInit() {
        this.getYears();
        const jwthelper = new JwtHelperService();
        let current_user = jwthelper.decodeToken(localStorage.getItem('currentUser'));
        this.isIntervenant(localStorage.getItem('roles'));
        if(this.role_acces){
            this.displayedColumns = ['school_year', 'level', 'speciality', 'redoubling', 'establishment', 'establishment_city'];
        }else{
            this.displayedColumns = ['school_year', 'level', 'speciality', 'redoubling', 'establishment', 'establishment_city', 'actionsColumn'];
        }
        //this.getLogedUser();
        if(this.idapprenant != null){
            this.user = this.idapprenant;
        }
        this.getListScolarity(this.user);

    }
    isIntervenant(role){

        if(role == 'IS_INTERVENANT'){
            this.role_acces = true;
        }else{
            this.role_acces = false;
        }
    }

    getLogedUser(){
        if (localStorage.getItem('currentUser')) {
            const jwthelper = new JwtHelperService();
            let current_user = jwthelper.decodeToken(localStorage.getItem('currentUser'));
            this.userService.getUserByUsername(current_user['username']).subscribe( res => {
                if(res != 0 ){
                    this.user = res[0].id;
                    if(this.idapprenant != null){
                        this.user = this.idapprenant;
                    }
                    this.getListScolarity(this.user);
                }

            });


        }else{
            console.log('je crois que vous n\'etes pas connecté , merci de checker, sinon contactez l\'administration')
        }
    }

    getYears(){
        this.userService.getYears().subscribe(
            data => {
                this.years = data;
            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            }
        )
    }

    getListScolarity(id:number = 0){
        this.userService.getScolarity(id).subscribe(
            data => {
                //return;
                this.scolarityList = (data);


                this.dataSource = new TableDataSource<ScolarityEntity>(this.scolarityList,   ScolarityEntity , this.scolarityValidator);
                this.dataSource.datasourceSubject.subscribe(scolarityList => this.scolarityListChange.emit(scolarityList));
                this.showScolarityLoader = false;


            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            });
    }

    startEdit(row){

        row.startEdit();
        //row.editing = true;
    }
    confirmEditCreate(row){

        if(row.currentData.id === undefined){
            this.userService.newScolarity(this.user, row.currentData).subscribe(res => {
                 this.snackBar.openFromComponent(AlertsComponent,{
                     duration: 1000,
                     panelClass: ['alerts-snackbar-seccus'],
                     data:res
                 });

            });
        }else{
            this.userService.updateScolarity(row.currentData.id,row.currentData).subscribe(res => {
                this.snackBar.openFromComponent(AlertsComponent,{
                    duration: 1000,
                    panelClass: ['alerts-snackbar-seccus'],
                    data:res
                });
                row.confirmEditCreate();
                row.editing = false;
            });
        }


    }
    cancelOrDelete(row){
        if(row.currentData.id === undefined){

        }else{
            this.userService.deleteScolarity(row.currentData.id).subscribe(res => {
                this.snackBar.openFromComponent(AlertsComponent,{
                    duration: 1000,
                    panelClass: ['alerts-snackbar-seccus'],
                    data:res
                });
                row.cancelOrDelete();
            });
        }

    }

    createNew(){
        //this.pedagogydataSource.createNew();
        const dialogRefPeagogy = this.dialog.open(AddScolarityDialogueComponent, {
            width: '50%',
            data: {years: this.years,levels: this.levels ,  user: this.user}

        });

        dialogRefPeagogy.afterClosed().subscribe(result => {
            // this.getNotes();
            this.getListScolarity(this.user);
            //this.scolarityList.push(result);
            //this.dataSource = new TableDataSource<ScolarityEntity>(this.scolarityList,   ScolarityEntity , this.scolarityValidator);
        });
    }



}


