import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidatorService } from 'angular4-material-table';

@Injectable()
export class CommandeValidatorService implements ValidatorService {
    getRowValidator(): FormGroup {
        return new FormGroup({
            'date_cmd': new FormControl( Validators.required),
            'taux_conso': new FormControl(),
            'limite_conso': new FormControl(),
            'year_cmd': new FormControl(),
            'service_cmd': new FormControl(),
            'qte': new FormControl(),
            'nb_heure_facture': new FormControl(),
            'redection': new FormControl(),
            'totale': new FormControl(),
        });
    }
}