import {Component, Inject, OnInit} from '@angular/core';
import {UserService} from "../../_services/user.service";
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from "@angular/material";
import {
    DialogData,
    EditUploadControlDialogueComponent
} from "../edit-upload-control-dialogue/edit-upload-control-dialogue.component";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {AlertsComponent} from "../alerts/alerts.component";

@Component({
  selector: 'app-add-bulletin-dialogue',
  templateUrl: './add-bulletin-dialogue.component.html',
  styleUrls: ['./add-bulletin-dialogue.component.scss'],
    providers: [
        UserService
    ]
})
export class AddBulletinDialogueComponent implements OnInit {


    bulletinForm: FormGroup;
    id: number = this.data.trimester;
    apprenant: number = this.data.apprenant;
    intervenant: number = this.data.intervenant;
    selectedFile: FormData;
    formData:FormData;
  constructor(
      public dialogRef: MatDialogRef<AddBulletinDialogueComponent>,
      @Inject(MAT_DIALOG_DATA) private data: DialogData,
      private formBuilder: FormBuilder,
      private userService: UserService,
      public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
      this.bulletinForm = this.formBuilder.group({
          title: null,
          attachment: null,
          trimester: new FormControl({ value: this.id, disabled: true}),
          apprenant: new FormControl({ value: this.apprenant, disabled: true, visible: false}),
      });
      this.formData = new FormData();
  }



    onSubmit(){
        let formModel = this.bulletinForm.value;

        this.formData.append('trimester',(this.id).toString());
        this.formData.append('title',formModel['title']);
        this.formData.append('apprenant',(this.apprenant).toString());
        this.formData.append('intervenant',(this.intervenant).toString());
        //this.bulletinForm.value.get('attachment').setValue(this.selectedFile['uploadFile']);

        //return;
        this.userService.newBulletin(this.formData).subscribe(res => {

            this.snackBar.openFromComponent(AlertsComponent,{
                duration: 1000,
                panelClass: ['alerts-snackbar-seccus'],
                data:res
            });

            this.dialogRef.close();
        });
    }



    onFileChange(event) {
        let fileList: FileList = event.target.files;

        if(fileList.length > 0){
            let file: File = fileList[0];
            this.formData.append('uploadFile', file, file.name);
        }

        //this.selectedFile = this.formData;

        /*this.selectedFile = event.target.files[0];
        let reader = new FileReader();
        if(event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];
            console.log(file);
            reader.readAsDataURL(file);

            reader.onload = () => {
                this.bulletinForm.get('attachment').setValue({
                    filename: file.name,
                    filetype: file.type,
                    fileexe:  file.name.match(/\.[0-9a-z]+$/i),
                    value: reader.result.split(',')[1],
                })
            };
        }*/
    }

}
