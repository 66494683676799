import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import {IntervenantComponent} from './intervenant/intervenant.component';
import {IntervenantModule} from './intervenant/intervenant.module';
import {ApprenantComponent} from './apprenant/apprenant.component';
import {ApprenantModule} from './apprenant/apprenant.module';
import {SigninComponent} from './signin/signin.component';
import {AuthGuard} from "./_guards/auth.guard";
import {SignOutComponent} from "./sign-out/sign-out.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },
   {
        path: '',
        component: IntervenantComponent,
       canActivate: [AuthGuard],
        children: [
            {
                path: 'intervenant',
                canActivate: [AuthGuard],
                loadChildren: 'app/intervenant/intervenant.module#IntervenantModule'
    }]
   }, {
        path: '',
        component: ApprenantComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'apprenant',
                canActivate: [AuthGuard],
                loadChildren: 'app/apprenant/apprenant.module#ApprenantModule'
            }]
    },
    {
        path: 'login',
        component: SigninComponent,
    },
    {
        path: 'logout',
        component: SignOutComponent,
    }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    IntervenantModule,
    ApprenantModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
