import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ContactEntity} from "../../datas/contact-entity";
import {UserService} from "../../_services/user.service";
import {MatSnackBar} from "@angular/material";
import {AlertsComponent} from "../alerts/alerts.component";
import {JwtHelperService} from "@auth0/angular-jwt";

@Component({
  selector: 'app-shared-contact',
  templateUrl: './shared-contact.component.html',
  styleUrls: ['./shared-contact.component.scss'],
    providers: [UserService]
})
export class SharedContactComponent implements OnInit {

    contactForm = new FormGroup ({
        theme: new FormControl(),
        content: new FormControl()
    });
    user = null;
    role = null;

  constructor(private fb: FormBuilder, private userService: UserService, public snackBar: MatSnackBar) { }

  ngOnInit() {

      this.user = localStorage.getItem('username');
      this.role = localStorage.getItem('roles');
      if(this.user != null){
          //this.getLogedUser(this.user);
      }


  }

    createForm() {
        this.contactForm = this.fb.group({
            theme: ['', Validators.required],
            content: ['', Validators.required],
        });
    }

    onSubmit(){

      let contact = new ContactEntity();
      contact.date_contact = new Date();
      contact.theme = this.contactForm.value.theme;
      contact.content = this.contactForm.value.content;
      contact.user = this.user;
      contact.role = this.role;


      this.addMessage(contact);
    }


    addMessage(contact){

        this.userService.addContactMessage(contact).subscribe(
            data => {
                this.snackBar.openFromComponent(AlertsComponent,{
                    duration: 1000,
                    panelClass: ['alerts-snackbar-seccus'],
                    data:data
                });
                this.contactForm.reset();
            },
            err => {
                this.snackBar.openFromComponent(AlertsComponent,{
                    duration: 1000,
                    panelClass: ['alerts-snackbar-error'],
                    data:'Echec d\'envoie '
                });
            });

    }

}
