import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TableDataSource, ValidatorService} from "angular4-material-table";
import {UserService} from "../../_services/user.service";
import {FactureEntity} from "../../datas/facture-entity";
import {FactureValidatorService} from "./facture-validator.service";
import {Router,RouterModule} from "@angular/router";

@Component({
  selector: 'app-mes-factures',
  templateUrl: './mes-factures.component.html',
  styleUrls: ['./mes-factures.component.scss'],
    providers: [
        {provide: ValidatorService, useClass: FactureValidatorService },
        UserService
    ],
})
export class MesFacturesComponent implements OnInit {


    @Input() factureList;
    @Output() factureListChange = new EventEmitter<any>();
    displayedColumns = ['id' , 'numero' , 'mere' , 'pere',  'date_factures' , 'nb' , 'totale','x'];
    dataSource: TableDataSource<FactureEntity>;


    constructor(private userService: UserService , private factureValidator: ValidatorService, private router: Router) { }

  ngOnInit() {
        this.getFactures();
  }
    cmdTimeStatmpToDate(id){
        let date = new Date(id *1000);
        let test = date.toLocaleDateString();
        return test;
    }
    getFactures(){
        this.userService.getFactures().subscribe(
            data => {
                //console.log(data); return;

                this.displayedColumns = ['id' , 'numero' , 'mere' , 'pere',  'date_factures' , 'nb' , 'totale','x'];
                this.factureList = (data);

                this.dataSource = new TableDataSource<FactureEntity>(this.factureList,   FactureEntity , this.factureValidator);
                this.dataSource.datasourceSubject.subscribe(factureList => this.factureListChange.emit(factureList));
                //console.log(this.dataSource);

            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            });
    }



}
