import {LOCALE_ID, NgModule} from '@angular/core';
import {HomeComponent} from './home/home.component';
import {IntervenantRoutingModule} from './intervenant-routing.module';
import {MatButtonModule, MatFormFieldModule, MatIconModule} from '@angular/material';
import { ApprenantsComponent } from './apprenants/apprenants.component';
import { PlanningComponent } from './planning/planning.component';
import { ConversationsComponent } from './conversations/conversations.component';
import { RapportsComponent } from './rapports/rapports.component';
import { ContactComponent } from './contact/contact.component';
import {SharedModule} from '../shared/shared.module';




@NgModule({
    imports: [
        IntervenantRoutingModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        SharedModule,



    ],
    declarations: [
        HomeComponent,
        ApprenantsComponent,
        PlanningComponent,
        RapportsComponent,
        ContactComponent,
        ConversationsComponent,


    ],
    providers : [
        { provide: LOCALE_ID, useValue: 'fr' }
    ],
})
export class IntervenantModule {
}
