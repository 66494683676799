import {Component, Inject, OnInit} from '@angular/core';
import {UserService} from "../../_services/user.service";
import {AddControlDialogueComponent} from "../add-control-dialogue/add-control-dialogue.component";
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from "@angular/material";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subject} from "../pedagogy/pedagogy.component";
import {AlertsComponent} from "../alerts/alerts.component";
import {ControlEntity} from "../../datas/control-entity";

export interface DialogData {
    trimester: number;
    apprenant: number;
    intervenant: number;
    subject: number
    trm: number
    mat: number
}

@Component({
  selector: 'app-add-new-control-dialogue',
  templateUrl: './add-new-control-dialogue.component.html',
  styleUrls: ['./add-new-control-dialogue.component.scss'],
    providers: [
        UserService
    ]
})
export class AddNewControlDialogueComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<AddControlDialogueComponent>,
               @Inject(MAT_DIALOG_DATA) private data: DialogData,
               private formBuilder: FormBuilder,
               private userService: UserService,
               public snackBar: MatSnackBar) { }

    submitted = false;
    controlForm: FormGroup;
    subjects = [

    ];
    selectedSubject;
    selectedTrimester;

    trimesters = [];
    //trimester: number = this.data.trimester;
    //intervenant: number = this.data.intervenant;
    apprenant: number = this.data.apprenant;
    trm: number = this.data.trm;
    mat: number = this.data.mat;
    //subject : number  = this.data.subject;
    //selectedSubject = this.data.subject;

  ngOnInit() {
        this.getSubjects();
        this.getTrimesters();


      this.controlForm = this.formBuilder.group({
          date_control: ['', Validators.required],
          note: [null],
          selectedSubject: ['', Validators.required],
          selectedTrimester: ['', Validators.required],
      });




  }
    getSubjects(){
        this.userService.getAllSubjects().subscribe( res => {
            this.subjects = res;
           // this.getAllNotesApprenant(this.user)
            if(this.mat != null){
                this.selectedSubject = this.mat;
            }
        });
    }
    getTrimesters(){
        this.userService.getTrimesters().subscribe( res => {
            this.trimesters = res;
            // this.getAllNotesApprenant(this.user)
            if(this.trm != null){
                this.selectedTrimester = this.trm.toString();
            }
        });
    }
    onNoClick(): void {
        this.dialogRef.close();
    }


    onSubmit() {

        this.submitted = true;
        let test = this.controlForm.value;
        //console.log(test); return;
        let control = new ControlEntity();
        control.id = null;
        control.date_control = this.controlForm.value.date_control;
        control.subject = this.controlForm.value.selectedSubject;
        if(this.controlForm.value.note != null || this.controlForm.value.note == ""){
            control.note = this.controlForm.value.note;
        }else{
            control.note = null;
        }

        control.bulletin = null;
        control.uploads = null;

        control.trimester = this.controlForm.value.selectedTrimester;
        //control.intervenant = this.intervenant;
        control.apprenant = this.apprenant;
        if(this.trm != null){
            control.trimester = this.trm;
        }
        if(this.mat != null){
            control.subject = this.mat;
        }
        //console.log(control);return;
        // TODO: send data de backend api
        this.userService.newNote(this.apprenant, control).subscribe(res => {
            this.snackBar.openFromComponent(AlertsComponent,{
                duration: 1000,
                panelClass: ['alerts-snackbar-seccus'],
                data:res
            });

            this.dialogRef.close();
        });
    }


}
