import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidatorService } from 'angular4-material-table';

@Injectable()
export class PedagogyValidatorService implements ValidatorService {
    getRowValidator(): FormGroup {
        return new FormGroup({
            'date_suivi': new FormControl(),
            'chapter': new FormControl(),
            'master': new FormControl(),
            'to_work_on': new FormControl(),
            'mentor_comment': new FormControl(),
            'htp': new FormControl(),
            'hma': new FormControl(),
            'subject': new FormControl(),
        });
    }
}