import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidatorService } from 'angular4-material-table';

@Injectable()
export class FactureValidatorService implements ValidatorService {
    getRowValidator(): FormGroup {
        return new FormGroup({
            'id': new FormControl( Validators.required),
            'date_factures': new FormControl(),
            'mere': new FormControl(),
            'numero': new FormControl(),
            'pere': new FormControl(),
            'remarques': new FormControl(),
            'nb': new FormControl(),
            'totale': new FormControl(),
        });
    }
}