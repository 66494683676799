import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import {ApprenantProfile} from "../datas/apprenant-profile";
import {ScolarityEntity} from "../datas/scolarity-entity";
import {PedagogyEntity} from "../datas/pedagogy-entity";
import {NotesEntity} from "../datas/notes-entity";
import {ControlEntity} from "../datas/control-entity";
import {BulletinEntity} from "../datas/bulletin-entity";
import {ChatEntity} from "../datas/chat-entity";
import {ContactEntity} from "../datas/contact-entity";
import {PlanningEntity} from "../datas/planning-entity";
import {CalendarEvent} from "angular-calendar";
import {ReportEntity} from "../datas/report-entity";
import {JwtHelperService} from "@auth0/angular-jwt";
import {JwtInterceptor} from "../_helpers/jwt.interceptor";
import {ProfsEntity} from "../datas/profs-entity";
import {Subject} from "../shared/pedagogy/pedagogy.component";


//const endpoint = 'http://127.0.0.1:8000/api/';
const endpoint = 'https://api.az-educ.fr/api/';


let httpOptions = {
    headers: new HttpHeaders({
        'Content-Type':  'application/json',

    })
};
@Injectable()
export class IntervenantService {

    subjects = [];

    constructor(private http: HttpClient){
        this.getSubjects();
    }
    getSubjects(){
        this.getIntervenantSubjects().subscribe( res => {
            this.subjects = [res];
        });
    }

    getIntervenantSubjects(): Observable<any> {
        return this.http.get<any>(endpoint + 'intervenant/subjects/show', httpOptions)
            .pipe(
                catchError(this.handleError('SUBJECTS ERROR '))
            );
    }

    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            console.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}