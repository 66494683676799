import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../_services/user.service";
import {TableDataSource, ValidatorService} from "angular4-material-table";
import {ReglementValidatorService} from "./reglement-validator.service";
import {ReglementEntity} from "../../datas/reglement-entity";


@Component({
  selector: 'app-mes-regelements',
  templateUrl: './mes-regelements.component.html',
  styleUrls: ['./mes-regelements.component.scss'],
    providers: [
        {provide: ValidatorService, useClass: ReglementValidatorService },
        UserService
    ],
})
export class MesRegelementsComponent implements OnInit {

    @Input() reglementList;
    @Output() reglementListChange = new EventEmitter<any>();
    //displayedColumns = ['date_reglement' , 'numero' , 'date_echeance' , 'montant',  'type_reglement'];
    displayedColumns = ['date_reglement' , 'numero'  , 'montant',  'type_reglement'];
    dataSource: TableDataSource<ReglementEntity>;
    id: number;
    private sub: any;
    total_reglement : number;

  constructor(private userService: UserService , private reglementValidator: ValidatorService ,private route: ActivatedRoute) { }

  ngOnInit() {
      this.sub = this.route.params.subscribe(params => {
          this.id = +params['id']; // (+) converts string 'id' to a number

          // In a real app: dispatch action to load the details here.
      });
      //console.log(this.id);
    this.getFactureReglements(this.id);
  }
    getTypeReglement(id){
      if(id == 2 ){
          return 'chéque';
      }else{
          return 'virement';
      }

    }

    cmdTimeStatmpToDate(id){
        let date = new Date(id *1000);
        let test = date.toLocaleDateString();
        return test;
    }
    getTotalCost(){
      this.total_reglement = this.reglementList.map(t => parseFloat(t.montant) ).reduce((acc, value) => acc + value, 0);
        return this.total_reglement;
    }

    getFactureReglements(id){
        this.userService.getFactureReglements(id).subscribe(
            data => {
                //console.log(data); return;

               // this.displayedColumns = ['date_reglement',  'type_reglement' , 'numero' , 'date_echeance' , 'montant'];
                this.displayedColumns = ['date_reglement'  , 'montant',  'type_reglement'];
                this.reglementList = (data);

                this.dataSource = new TableDataSource<ReglementEntity>(this.reglementList,   ReglementEntity , this.reglementValidator);
                this.dataSource.datasourceSubject.subscribe(reglementList => this.reglementListChange.emit(this.reglementList));
                //console.log(this.dataSource);

            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            });
    }
}
