import {Component, Inject, OnInit} from '@angular/core';
import {UserService} from "../../_services/user.service";
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from "@angular/material";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Level, school_year, ScolarityEntity} from "../../datas/scolarity-entity";
import {AlertsComponent} from "../alerts/alerts.component";

export interface DialogData {
    levels: Level;
    years: school_year;
    user: string;
}
@Component({
  selector: 'app-add-scolarity-dialogue',
  templateUrl: './add-scolarity-dialogue.component.html',
  styleUrls: ['./add-scolarity-dialogue.component.scss'],
    providers: [UserService]
})
export class AddScolarityDialogueComponent implements OnInit {
    levels = this.data.levels;
    years = this.data.years;
    user = this.data.user;
    selectedYear;
    selectedLevel;


    newScolarity = new FormGroup ({
        school_year: new FormControl(),
        level: new FormControl(),
        redoubling: new FormControl(),
        speciality: new FormControl(),
        establishment: new FormControl(),
        establishment_city: new FormControl(),
    });

  constructor(
      public dialogRef: MatDialogRef<AddScolarityDialogueComponent>,
      @Inject(MAT_DIALOG_DATA) private data: DialogData,
      private formBuilder: FormBuilder,
      private userService: UserService,
      public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
      this.newScolarity = this.formBuilder.group({
          school_year: ['', Validators.required],
          level: ['', Validators.required],
          redoubling: ['', Validators.required],
          speciality: ['', Validators.required],
          establishment: ['', Validators.required],
          establishment_city: ['', Validators.required],
      });

  }



  onSubmit(){

      let p = this.newScolarity.value;
      let scolarity = new ScolarityEntity();
      scolarity.school_year = p.school_year;
      scolarity.level = p.level;
      if(p.redoubling == "0"){scolarity.redoubling = false}else{scolarity.redoubling = true};
      scolarity.redoubling = p.redoubling;
      scolarity.speciality = p.speciality;
      scolarity.establishment = p.establishment;
      scolarity.establishment_city = p.establishment_city;

     //console.log(scolarity);return;
      // TODO: send data de backend api
      this.userService.newScolarity(this.user, scolarity).subscribe(res => {
          this.snackBar.openFromComponent(AlertsComponent,{
              duration: 1000,
              panelClass: ['alerts-snackbar-seccus'],
              data:res
          });

          this.dialogRef.close();
      });
  }


    onLevelChange(event){
        this.selectedLevel = event.value;
    }
    onYearChange(event){
        this.selectedYear = event.value;
    }

}
