import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import localeFr from '@angular/common/locales/fr';
import { BrowserModule }    from '@angular/platform-browser';

import {NgxPrintModule} from 'ngx-print';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import {MatIconModule} from '@angular/material/icon';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { IntervenantComponent } from './intervenant/intervenant.component';
import { ApprenantComponent } from './apprenant/apprenant.component';

import {
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatTooltipModule
} from '@angular/material';

import { SigninComponent } from './signin/signin.component';
import {ApprenantModule} from './apprenant/apprenant.module';
import {IntervenantModule} from './intervenant/intervenant.module';
import {JwtInterceptor} from "./_helpers/jwt.interceptor";
import {ErrorInterceptor} from "./_helpers/error.interceptor";
import { SignOutComponent } from './sign-out/sign-out.component';
import {JwtModule} from "@auth0/angular-jwt";
import {registerLocaleData} from "@angular/common";


export function tokenGetter() {
    return localStorage.getItem('myitem');
}
registerLocaleData(localeFr, 'fr');

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    BrowserModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    MatIconModule,
    MatListModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
      MatTooltipModule,
      NgxPrintModule,
    ApprenantModule,
    IntervenantModule,
      MatAutocompleteModule,
    // SharedModule,


      JwtModule.forRoot({
          config: {
              tokenGetter: tokenGetter,
              //whitelistedDomains: ['http://127.0.0.1:8000','http://127.0.0.1:4200', 'http://localhost:4200','http://api.az-educ.fr'],
              whitelistedDomains: ['https://az-educ.fr', 'http://az-educ.fr', 'http://api.az-educ.fr', 'https://api.az-educ.fr'],
              blacklistedRoutes: ['localhost:3001/auth/']
          }
      })



  ],
  declarations: [
    AppComponent,
    IntervenantComponent,
    ApprenantComponent,
    SigninComponent,
    SignOutComponent,
    // RapportComponent,



  ],
  providers: [
      { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      { provide: LOCALE_ID, useValue: 'fr' }
  ],
  bootstrap: [AppComponent],
  exports: []
 // entryComponents: [Ngm]

})
export class AppModule { }
