import { NgModule } from '@angular/core';
import {HomeComponent} from './home/home.component';
import {ApprenantRoutingModule} from './apprenant-routing.module';
import {
    MatAccordion,
    MatButtonModule, MatCalendarView,
    MatCardModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDatepickerToggle,
    MatExpansionModule,
    MatFormField,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule, MatMenuModule,
    MatNativeDateModule,
    MatOptionModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBar, MatSnackBarModule,
    MatTab,
    MatTabGroup,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,MatAutocompleteModule
} from '@angular/material';
import { SuiviComponent } from './suivi/suivi.component';
import { ProfsComponent } from './profs/profs.component';
import { PlanningComponent } from './planning/planning.component';
import { ProfilComponent } from './profil/profil.component';
import { InboxComponent } from './inbox/inbox.component';
import { ProfilFormComponent } from './profil-form/profil-form.component';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ScolarityComponent} from '../shared/scolarity/scolarity.component';
import {PedagogyComponent} from '../shared/pedagogy/pedagogy.component';
import {NotesComponent} from '../shared/notes/notes.component';
import {FilterProfsPipe} from '../pipes/filter-profs.pipe';
import {ChatComponent} from '../shared/chat/chat.component';
import {CalendarComponent} from '../shared/calendar/calendar.component';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { ContactComponent } from './contact/contact.component';
import {SharedModule} from '../shared/shared.module';
import { FactureComponent } from './facture/facture.component';
import { MesCommandesComponent } from './mes-commandes/mes-commandes.component';
import { MesFacturesComponent } from './mes-factures/mes-factures.component';
import { FactureDetailsComponent } from './facture-details/facture-details.component';
import {RouterModule} from "@angular/router";
import {NgxPrintModule} from "ngx-print";
import { MesRegelementsComponent } from './mes-regelements/mes-regelements.component';
import { MesPlanningsComponent } from './mes-plannings/mes-plannings.component';
import { FactureDetailsPrintComponent } from './facture-details-print/facture-details-print.component';




registerLocaleData(localeFr);

@NgModule({
    imports: [
        BrowserModule,
        ApprenantRoutingModule,
        MatIconModule,
        MatButtonModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        MatOptionModule,
        FormsModule,
        MatExpansionModule,
        MatTableModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        MatListModule,
        MatChipsModule,
        MatCardModule,
        MatToolbarModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatMenuModule,
        NgbModalModule,
        RouterModule,
        SharedModule,
        NgxPrintModule,
        MatAutocompleteModule,
    ],
    declarations: [
        HomeComponent,
         SuiviComponent,
        ProfsComponent,
        PlanningComponent,
        ProfilComponent,
        InboxComponent,
        ProfilFormComponent,
        // ScolarityComponent,
        // PedagogyComponent,
       // NotesComponent,
        FilterProfsPipe,
        ContactComponent,
        FactureComponent,
        MesCommandesComponent,
        MesFacturesComponent,
        FactureDetailsComponent,
        MesRegelementsComponent,
        MesPlanningsComponent,
        FactureDetailsPrintComponent,







    ],
    providers : [FilterProfsPipe
    ],

})
export class ApprenantModule {
}
