import {Component, EventEmitter, Injectable, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {TableDataSource, ValidatorService} from 'angular4-material-table';
import {PedagogyValidatorService} from './pedagogy-validator.service';
import {PedagogyEntity} from '../../datas/pedagogy-entity';
import {ScolarityEntity} from "../../datas/scolarity-entity";
import {UserService} from "../../_services/user.service";
import {AlertsComponent} from "../alerts/alerts.component";
import {MatDialog, MatSnackBar} from "@angular/material";
import {JwtHelperService} from "@auth0/angular-jwt";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AddControlDialogueComponent} from "../add-control-dialogue/add-control-dialogue.component";
import {AddPedagogyDialogueComponent} from "../add-pedagogy-dialogue/add-pedagogy-dialogue.component";
import {ShowPedagogyDialogueComponent} from "../show-pedagogy-dialogue/show-pedagogy-dialogue.component";

export interface Level {
    value: string;
    viewValue: string;
}

export interface Master {
    id: number;
    label: string;
}
export interface Notion {
    id: number;
    label: string;
}

export interface Subject {
    id: number;
    label: string;
    code: string
}
@Component({
  selector: 'app-pedagogy',
    providers: [
        {provide: ValidatorService, useClass: PedagogyValidatorService },
        UserService
    ],
  templateUrl: './pedagogy.component.html',
  styleUrls: ['./pedagogy.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PedagogyComponent implements OnInit {
    @Input() idapprenant: number;

    pedagogyAdded: AddPedagogyDialogueComponent;
    pedagogyAddedShow: ShowPedagogyDialogueComponent;



    @ViewChild(AddPedagogyDialogueComponent) child: AddPedagogyDialogueComponent;
    @ViewChild(ShowPedagogyDialogueComponent) childn: ShowPedagogyDialogueComponent;
    //displayedColumns = [  'subject', 'date_suivi', 'chapter', 'master', 'to_work_on', 'mentor_comment', 'htp', 'hma', 'actionsColumn'];
    displayedColumns = [  'date_suivi', 'subject', 'master','chapter', 'to_work_on', 'mentor_comment',  'actionsColumn'];
    pedagogydataSource: TableDataSource<PedagogyEntity>;
    pedagogyForm: FormGroup;
    user;
    masters : Master[] = [
        {id: 1, label: 'Pas Acquis'},
        {id: 2, label: 'Moyen'},
        {id: 3, label: 'Assez Bien'},
        {id: 4, label: 'Bien '},
        {id: 5, label: 'Acquis'},
    ];
    showPedagogyLoader = true;
    /*subjects: Subject[] = [
        {id: 0, label: 'Autre', code: 'Autre'},
        {id: 1, label: 'Français', code: 'Fr'},
        {id: 2, label: 'Mathématiques', code: 'Math'},
        {id: 3, label: 'Physique-Chimie', code: 'PC'},
        {id: 4, label: 'Anglais', code: 'EN'},
        {id: 5, label: 'Espagnol', code: 'ES'},
        {id: 6, label: 'Histoire géographie', code: 'HG'},
        {id: 7, label: 'Français Langue Etrangère', code: 'FLE'},
        {id: 8, label: 'Philosophie', code: 'PHILO'},
        {id: 9, label: 'SVT', code: 'SVT'},
    ];*/

    subjects: Subject[] = [
        {id: 0, label: 'Autre', code: 'Autre'},
        {id: 5, label: 'Français', code: 'Fr'},
        {id: 6, label: 'Mathématiques', code: 'Math'},
        {id: 7, label: 'Physique-Chimie', code: 'PC'},
        {id: 8, label: 'Anglais', code: 'EN'},
        {id: 9, label: 'Espagnol', code: 'ES'},
        {id: 10, label: 'Histoire géographie', code: 'HG'},
        {id: 11, label: 'Français Langue Etrangère', code: 'FLE'},
        {id: 12, label: 'Philosophie', code: 'PHILO'},
        {id: 13, label: 'SVT', code: 'SVT'},
        {id: 14, label: 'Moyenne generale', code: 'MG'},
        {id: 15, label: 'Conditions gÃ©nÃ©rales', code: 'CG'},
    ];
    selectedSubject;
    selectedMaster;
    role_acces;
    @Input() pedagogyList ;
    @Output() pedagogyListChange = new EventEmitter<any>();



  constructor(private pedagogyValidator: ValidatorService, private userService: UserService,  public snackBar: MatSnackBar,private fb: FormBuilder, public dialog: MatDialog) { }

  ngOnInit() {
      const jwthelper = new JwtHelperService();
      let current_user = jwthelper.decodeToken(localStorage.getItem('currentUser'));

      this.isIntervenant(localStorage.getItem('roles'));
      if(this.role_acces){
          //this.displayedColumns = [  'subject', 'date_suivi', 'chapter', 'master', 'to_work_on', 'mentor_comment', 'htp', 'hma','view', 'actionsColumn'];
          this.displayedColumns = [  'date_suivi', 'subject',  'chapter', 'master', 'to_work_on', 'mentor_comment', 'view', 'actionsColumn'];
      }else{
          this.displayedColumns = [  'date_suivi', 'subject',  'chapter', 'master', 'to_work_on', 'mentor_comment','view'];
      }

      if(this.idapprenant != null){

          this.user = this.idapprenant;
      }
      this.getListPedagogy(this.user);

      this.pedagogyForm = this.fb.group({
          subject: []
      });

  }
    isIntervenant(role){

        if(role == 'IS_INTERVENANT'){
            this.role_acces = true;
        }else{
            this.role_acces = false;
        }
    }

    getLogedUser(){
        if (localStorage.getItem('currentUser')) {
            const jwthelper = new JwtHelperService();
            let current_user = jwthelper.decodeToken(localStorage.getItem('currentUser'));
            this.userService.getUserByUsername(current_user['username']).subscribe( res => {

                if(res != 0 ){
                    this.user = res[0].id;
                    if(this.idapprenant != null){
                        this.user = this.idapprenant;
                    }
                    this.getListPedagogy(this.user);
                }

            });


        }else{
            console.log('je crois que vous n\'etes pas connecté , merci de checker, sinon contactez l\'administration')
        }
    }

    getListPedagogy(id:number = 0){
        this.userService.getSuivi(id).subscribe(
            data => {
                    //console.log(data);
                    data.forEach(function(element) {
                        //console.log(element.date_suivi);
                        element.date_suivi = new Date(element.date_suivi * 1000);
                    });
                this.pedagogyList = (data);


                this.pedagogydataSource = new TableDataSource<PedagogyEntity>(this.pedagogyList,   PedagogyEntity , this.pedagogyValidator);
                this.pedagogydataSource.datasourceSubject.subscribe(pedagogyList => this.pedagogyListChange.emit(pedagogyList));
                this.showPedagogyLoader = false;
            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            });
    }


    createNew(){

      //this.pedagogydataSource.createNew();

        const dialogRefPeagogy = this.dialog.open(AddPedagogyDialogueComponent, {
            width: '50%',
            data: {subjects: this.subjects, masters: this.masters, user: this.user}

        });

        dialogRefPeagogy.afterClosed().subscribe(result => {
            // this.getNotes();
            this.getListPedagogy(this.user);


        });


    }

    startEdit(row){

        row.startEdit();
        //row.editing = true;
    }
    confirmEditCreate(row){
      if(row.currentData.id === undefined){
            this.userService.newSuivi(this.user, row.currentData).subscribe(res => {
                this.snackBar.openFromComponent(AlertsComponent,{
                    duration: 1000,
                    panelClass: ['alerts-snackbar-seccus'],
                    data:res
                });


            });
        }else{
          // console.log(row.currentData);return;
            this.userService.updateSuivi(row.currentData.id,row.currentData).subscribe(res => {
                //console.log(row.currentData); return;
                this.snackBar.openFromComponent(AlertsComponent,{
                    duration: 1000,
                    panelClass: ['alerts-snackbar-seccus'],
                    data:res
                });


            });
        }
        row.confirmEditCreate();
        row.editing = false;

    }

    cancelOrDelete(row){

        this.userService.deleteSuivi(row.currentData.id).subscribe(
            data => {
                row.cancelOrDelete();

            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            });
        //row.cancelOrDelete();
    }

    show(row){
        const dialogRefPeagogy = this.dialog.open(ShowPedagogyDialogueComponent, {
            width: '50%',
            data: {subjects: this.subjects, masters: this.masters, user: this.user, row: row.currentData}

        });

        dialogRefPeagogy.afterClosed().subscribe(result => {});
    }

}
