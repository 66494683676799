import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { IntervenantComponent } from './intervenant.component';
import {HomeComponent} from './home/home.component';
import {ApprenantsComponent} from './apprenants/apprenants.component';
import {PlanningComponent} from './planning/planning.component';
import {RapportsComponent} from './rapports/rapports.component';
import {ConversationsComponent} from './conversations/conversations.component';
import {ContactComponent} from './contact/contact.component';
import {AuthGuard} from "../_guards/auth.guard";



const routes: Routes = [{
    path: 'intervenant',
    component: IntervenantComponent,
    canActivate: [AuthGuard],
    data: {
        expectedRole: 'IS_INTERVENANT'
    },
    children: [{
        path: '',
        component: HomeComponent,
    }, {
        path: 'apprenants',
        component: ApprenantsComponent,
    }, {
        path: 'planning',
        component: PlanningComponent,
    }, {
        path: 'rapports',
        component: RapportsComponent,
    }, {
        path: 'conversations',
        component: ConversationsComponent,
    }, {
        path: 'contact',
        component: ContactComponent,
    }],
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class IntervenantRoutingModule {
}
