import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators, ReactiveFormsModule} from '@angular/forms';
import {AuthenticationService} from "../_services/authentication.service";
import { first } from 'rxjs/operators';
import {UserService} from "../_services/user.service";

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
    providers: [UserService]

})
export class SigninComponent implements OnInit {

  public loginform: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';

  constructor(
      private fb: FormBuilder,
      private router: Router,
      private route: ActivatedRoute,
      private authenticationService: AuthenticationService,
      private userService: UserService) {

  }

  ngOnInit() {
      this.loginform = this.fb.group ( {
          //email: ['', Validators.required, Validators.email],
          //password: ['', Validators.required]
          email: [null , Validators.compose ( [ Validators.required ] )] , password: [null , Validators.compose ( [ Validators.required ] )]
      } );

      // reset login status
      this.authenticationService.logout();

      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

    // convenience getter for easy access to form fields
    get f() { return this.loginform.controls; }


    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginform.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {


                    this.userService.getUserRole().subscribe(res => {

                        let roleex = res;
                        localStorage.setItem('roles',res);
                        let role = localStorage.getItem('roles');
                        if(role == 'IS_INTERVENANT'){
                            this.router.navigate(['/intervenant']);
                        }else if(role == 'IS_APPRENANT'){
                            this.router.navigate(['/apprenant']);
                        }else{
                            console.log('not connected')
                        }
                    });

                    // this.router.navigate([this.returnUrl]);
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });



    }

}
