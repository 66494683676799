import { Component, OnInit } from '@angular/core';
import {UserService} from "../../_services/user.service";
import {TableDataSource} from "angular4-material-table";
import {ReglementEntity} from "../../datas/reglement-entity";

export interface PlanningCmd {
    id: number;
    date: string;
    bb: string;
    cc: string;
    centre_nom: string;
    intervenant: string;
    salle_nom: string;
    libelle: string;
    remarques: string;
    duree: string;
    absence: string;
    justifie: string;
}

@Component({
  selector: 'app-mes-plannings',
  templateUrl: './mes-plannings.component.html',
  styleUrls: ['./mes-plannings.component.scss'],
    providers: [

        UserService
    ],
})
export class MesPlanningsComponent implements OnInit {

    list_cmds;
    total_duration;
  constructor(private  userService: UserService) { }

    displayedColumns = ['date' , 'crenaux' , 'salle_nom' , 'libelle',  'intervenant' , 'duree','abs','conso','justifie','remarques'];
    dataSource: TableDataSource<PlanningCmd>;
  ngOnInit() {
      this.getCommandes();
  }
    cmdTimeStatmpToDate(id){
        let date = new Date(id *1000);
        let test = date.toLocaleDateString();
        return test;
    }

    getTotalDuration(item){
        this.total_duration = 0;
        this.total_duration = item.map(t => parseInt(t.duree) ).reduce((acc, value) => acc + value, 0);
        return this.total_duration;
    }
    checkAbsence(abs){
      if(abs == "oui" || abs == 'non'){
         return '*';
      }else{
          return '';
      }

    }
    getTotalAbsences(a,b){
      let c = (a/b) * 100;
        return Math.round(c) + ' %';
    }


    getCommandes(){
        this.userService.getCmdsWithPlanning().subscribe(
            data => {
                // console.log(data); return;
                this.list_cmds = data;
                //displayedColumns = ['date' , 'crenaux' , 'salle_nom' , 'libelle',  'intervenant','remarques','absence','justifie'];
                //this.dataSource = data;
            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            });
    }

}
