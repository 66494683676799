import { Component, OnInit } from '@angular/core';
import {ROUTES} from './apprenant-menu';


@Component({
  selector: 'app-apprenant',
  templateUrl: './apprenant.component.html',
  styleUrls: ['./apprenant.component.scss']
})
export class ApprenantComponent implements OnInit {
    menu = ROUTES;
  constructor() { }

  ngOnInit() {
  }

}
