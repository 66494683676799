export class ReglementEntity {
    public id :number;
    public date_reglement : string;
    public numero : string;
    public date_echeance : string;
    public montant : string;
    public type_reglement: string;
}


