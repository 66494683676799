import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apprenants',
  templateUrl: './apprenants.component.html',
  styleUrls: ['./apprenants.component.scss']
})
export class ApprenantsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
