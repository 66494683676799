import { Component, OnInit } from '@angular/core';
import {ROUTES} from './intervenant-menu';
@Component({
  selector: 'app-intervenant',
  templateUrl: './intervenant.component.html',
  styleUrls: ['./intervenant.component.scss']
})
export class IntervenantComponent implements OnInit {

  menu = ROUTES;
  constructor() { }

  ngOnInit() {
  }

}
