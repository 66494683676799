import { Component, OnInit } from '@angular/core';
import {ApprenantProfile} from '../../datas/apprenant-profile';
import {HttpClient} from "@angular/common/http";
import {UserService} from "../../_services/user.service";
import {MatSnackBar} from '@angular/material';
import {AlertsComponent} from "../../shared/alerts/alerts.component";
import {JwtHelperService} from "@auth0/angular-jwt";
import {toInteger} from "@ng-bootstrap/ng-bootstrap/util/util";
import {map, startWith} from "rxjs/operators";
import {Observable} from "rxjs/Rx";
import {FormControl} from "@angular/forms";
import {isUndefined} from "util";

export interface Sexe {
    id: number;
    label: string;
}
export interface City {
    id: number;
    label: string;
}

@Component({
  selector: 'app-profil-form',
  templateUrl: './profil-form.component.html',
  styleUrls: ['./profil-form.component.scss']
})


export class ProfilFormComponent implements OnInit {
    submitted = false;
    selectedOption = null;
    currentuser;
    user;
    profil : ApprenantProfile;
    showLoader = true;
    genders: Sexe[] = [
        {id: 1, label: 'Mme'},
        {id: 3, label: 'Mr'},
        {id: 2, label: 'M'}
    ];

    //cities: City[] = [];
    cities = [];
    selectedSexe;
    selectedCity;

    myControl = new FormControl();
    filteredOptions: Observable<any[]>;

  constructor(private http: HttpClient, private userService: UserService, public snackBar: MatSnackBar) {  }

  ngOnInit() {

      /*this.filteredOptions = this.myControl.valueChanges
          .pipe(
              startWith(''),
              map(value => this._filter(value))
          );*/



      //this.getLogedUser();
      this.getCities();

  }
  onSubmit() {

      this.submitted = true;

      this.profil.sexe = this.selectedSexe;
      this.profil.city = this.selectedCity;
        //console.log(this.profil); return;
       this.userService.updateUser(this.currentuser,this.profil).subscribe(res => {
           this.snackBar.openFromComponent(AlertsComponent,{
               duration: 1000,
               panelClass: ['alerts-snackbar-seccus'],
               data:res
           });

       });

  }

  getCities(){
      this.userService.getCities().subscribe(
          data => {
               this.cities.push(data) ;
              this.filteredOptions = this.myControl.valueChanges
                  .pipe(
                      startWith(''),
                      map(value => typeof value === 'string' ? value : value.label),
                      map(name => name ? this._filter(name) : this.cities[0].slice())
                  );
              this.getProfile();
          },
          err => {
              if (err.error instanceof Error) {
                  console.log("Client-side error occured.");
              } else {
                  console.log("Server-side error occured.");
              }
          });
  }

       getProfile(){
           this.userService.getProfile().subscribe(
               data => {
                   this.profil = data[0];

                   this.selectedSexe = Number(data[0].sexe.id);
                   let ville = this.cities[0].find(x => x.id == data[0].city);

                   this.selectedCity = ville;
                   this.showLoader = false;

               },
               err => {
                   if (err.error instanceof Error) {
                       console.log("Client-side error occured.");
                   } else {
                       console.log("Server-side error occured.");
                   }
               });
       }
    doSelectSexe(ev) {
            this.selectedSexe = ev.value;
    }
    doSelectCity(ev) {
           //if(!isUndefined(ev)){
               return this.selectedCity = ev;
           //}
    }
    displayFn(user?: any): string | undefined {
        return user ? user.label : undefined;
    }

    private _filter(name: string): any[] {
        const filterValue = name.toLowerCase();

        return this.cities[0].filter(option => option.label.toLowerCase().indexOf(filterValue) === 0);
    }


}
