import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from "@angular/material";
import {Subject} from "../pedagogy/pedagogy.component";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ControlEntity} from "../../datas/control-entity";
import {AlertsComponent} from "../alerts/alerts.component";
import {UserService} from "../../_services/user.service";


export interface DialogData {
    id: number;
}

@Component({
  selector: 'app-add-control-dialogue',
  templateUrl: './add-control-dialogue.component.html',
  styleUrls: ['./add-control-dialogue.component.scss'],
    providers: [
        UserService
    ]
})


export class AddControlDialogueComponent implements OnInit {

  constructor(
      public dialogRef: MatDialogRef<AddControlDialogueComponent>,
      @Inject(MAT_DIALOG_DATA) private data: DialogData,
      private formBuilder: FormBuilder,
      private userService: UserService,
      public snackBar: MatSnackBar) { }

    submitted = false;
    controlForm: FormGroup;

    id: number = this.data.id;


  ngOnInit() {
    //edit note
      this.controlForm = this.formBuilder.group({
          note: ['', Validators.required],
          //selectedSubject: ['', Validators.required],
      });


  }


    onNoClick(): void {
        this.dialogRef.close();
    }

    onSubmit() { this.submitted = true;

        let control = new ControlEntity();
        control.id = this.id;
        control.note = this.controlForm.value.note;
        //console.log(control);return;
        // TODO: send data de backend api
        this.userService.editNote(this.id, control).subscribe(res => {
            this.snackBar.openFromComponent(AlertsComponent,{
                duration: 1000,
                panelClass: ['alerts-snackbar-seccus'],
                data:res
            });

            this.dialogRef.close();
        });
    }
}
