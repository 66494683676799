import {
    Component,
    ChangeDetectionStrategy,
    ViewChild,
    TemplateRef, OnInit
} from '@angular/core';
import {
    startOfDay,
    endOfDay,
    subDays,
    addDays,
    endOfMonth,
    isSameDay,
    isSameMonth,
    addHours
} from 'date-fns';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    CalendarDateFormatter,
    CalendarEvent,
    CalendarEventAction,
    CalendarEventTimesChangedEvent, CalendarEventTitleFormatter,
    CalendarView, DAYS_OF_WEEK
} from 'angular-calendar';
import {HourDateFormatterProvider} from './hour-date-formatter-provider';
import {UserService} from "../../_services/user.service";
import {Observable, Subject} from "rxjs/Rx";
import {JwtHelperService} from "@auth0/angular-jwt";
import {NgxEventTitleFormatterProvider} from "./ngx-event-title-formatter.provider";
import {forEach} from "@angular/router/src/utils/collection";




const colors: any = {
    green: {
        primary: '#558b2f',
        secondary: '#558b2f'
    },
    purple: {
        primary: '#9c27b0',
        secondary: '#9c27b0'
    }
};


@Component({
    selector: 'app-calendar',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    providers: [{
        provide: CalendarDateFormatter,
        useClass: HourDateFormatterProvider,},
        {
            provide: CalendarEventTitleFormatter,
            useClass: NgxEventTitleFormatterProvider,
        },
        UserService
    ]

})
export class CalendarComponent implements OnInit {

    /*subjects: any[] = [
        //{id: 0, label: 'Autre', code: 'Autre'},
        {id: 5, label: 'Français', code: 'Fr'},
        {id: 6, label: 'Mathématiques', code: 'Math'},
        {id: 7, label: 'Physique-Chimie', code: 'PC'},
        {id: 8, label: 'Anglais', code: 'EN'},
        {id: 9, label: 'Espagnol', code: 'ES'},
        {id: 10, label: 'Histoire géographie', code: 'HG'},
        {id: 11, label: 'Français Langue Etrangère', code: 'FLE'},
        {id: 12, label: 'Philosophie', code: 'PHILO'},
        {id: 13, label: 'SVT', code: 'SVT'},

    ];*/
    subjects: any[] = [];
    current_user;
    locale: string = 'fr';
    user = 3;
    showLoader1: boolean = true;
    prof = null;
    weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
    studdents =[];
    weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];

    @ViewChild('modalContent')
    modalContent: TemplateRef<any>;

    view: CalendarView = CalendarView.Month;

    CalendarView = CalendarView;

    viewDate: Date = new Date();

    modalData: {
        action: string;
        event: CalendarEvent;
    };
    excludeDays: number[] = [0];

    actions: CalendarEventAction[] = [
        {
            label: '<i class="fa fa-fw fa-pencil"></i>',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.handleEvent('Edited', event);
            }
        },
        {
            label: '<i class="fa fa-fw fa-times"></i>',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.events = this.events.filter(iEvent => iEvent !== event);
                this.handleEvent('Deleted', event);
            }
        }
    ];

    refresh: Subject<any> = new Subject();



    events: CalendarEvent[] = [];


    activeDayIsOpen: boolean = true;

    constructor(private modal: NgbModal, private userService: UserService) {}


    ngOnInit(){

        if (localStorage.getItem('currentUser')) {
            const jwthelper = new JwtHelperService();
            this.current_user = jwthelper.decodeToken(localStorage.getItem('currentUser'));
            let rol = localStorage.getItem('roles');
            if( rol == 'IS_INTERVENANT'){
                this.getSubjects(1);
               // this.loadEvents(1);
            }else{
                this.getSubjects(0);
                //this.loadEvents(0);
            }


        }else{
            console.log('je crois que vous n\'etes pas connecté , merci de checker, sinon contactez l\'administration')
        }
        //this.loadEvents(this.user);

    }


    loadEvents(id){
        //console.log('calendar is loaded');
        if(id == 1){
            this.userService.getEventsIntervenants().subscribe(
                data => {

                   // return;
                    let list_events = [];
                    let day_first, day_end, title;
                    data.sort((a, b) => {
                        return <any>new Date(b.start) - <any>new Date(a.start);
                    });
                    data.forEach(ev => {
                        let clr;
                        if (ev.center_id == 1) {
                            clr = colors.purple;
                        } else {
                            clr = colors.green;
                        }

                        if (day_first == ev.start && day_end == ev.end && title == ev.title) {
                            day_first = ev.start; day_end = ev.end; title = ev.title;
                            //console.log('hoho');
                        } else {
                            //console.log('hohoccs');
                        list_events.push(
                            {
                                start: (new Date(ev.start)),
                                end: (new Date(ev.end)),
                                title: ev.title,
                                color: clr,
                                /*actions: this.actions,*/
                                allDay: false,
                                resizable: {
                                    beforeStart: true,
                                    afterEnd: true
                                },
                                meta: {
                                    //'matiere': {name: this.subjects[ev.subject.value].label , value: ev.subject.value},
                                    'matiere': {name: null, value: null},
                                    'centre': {
                                        'id': ev.center.id,
                                        'color': ev.center.color,
                                        'name': ev.center.name,
                                    },
                                    'planning': ev.datestamp,
                                    'students': [],
                                    'crenaux': {debut: ev.crenaux.debut, fin: ev.crenaux.fin},

                                },
                            },
                        );
                            day_first = ev.start; day_end = ev.end; title = ev.title;
                    }

                    });

                    this.showLoader1 = false;


                    this.events = list_events;
                    //console.log(list_events);
                    this.refresh.next();



                },
                err => {
                    if (err.error instanceof Error) {
                        console.log("Client-side error occured.");
                    } else {
                        console.log("Server-side error occured.");
                    }
                }
            );
        }else{
            //console.log('ssss isx loaded2');
            this.userService.getEventsApprenants().subscribe(
                data => {
                    //console.log(data);
                    //return;
                    let list_events = [];

                    for(let ev of data){

                        let students =[];
                        for( let student of ev.students){
                            //console.log(student);
                            students.push(
                                //[
                                [
                                    {'nom': student.nom,'prenom': student.prenom}
                                ]
                                //]
                            )
                        }

                        let clr;
                        if(ev.center.id == 1){
                            clr = colors.purple;
                        }else{
                            clr = colors.green;
                        }

                        list_events.push(
                            {
                                start: new Date(ev.start),
                                end: new Date(ev.end),
                                title: ev.title ,
                                color: clr,
                                /*actions: this.actions,*/
                                allDay: false,
                                resizable: {
                                    beforeStart: true,
                                    afterEnd: true
                                },
                                meta: {
                                    'matiere': {name: this.subjects[parseInt(ev.subject.value)].label , value: ev.subject.value},
                                    'centre' : {
                                        'id': ev.center.id,
                                        'color': ev.center.color,
                                        'name': ev.center.name,
                                    },
                                    'planning': ev.datestamp,
                                    'students': [],
                                    'planning_id': ev.planning_id
                                },
                            },
                        )
                    }
                    this.showLoader1 = false;
                    this.events = list_events;

                    //console.log('calendar isx loaded2');


                    this.refresh.next();

                },
                err => {
                    if (err.error instanceof Error) {
                        console.log("Client-side error occured.");
                    } else {
                        console.log("Server-side error occured.");
                    }
                }
            );
        }

    }

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        if (isSameMonth(date, this.viewDate)) {
            this.viewDate = date;
            if (
                (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
                events.length === 0
            ) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
            }
        }
    }

    eventTimesChanged({
                          event,
                          newStart,
                          newEnd
                      }: CalendarEventTimesChangedEvent): void {
        event.start = newStart;
        event.end = newEnd;
        this.handleEvent('Dropped or resized', event);
        this.refresh.next();

    }

    handleEvent(action: string, event: CalendarEvent): void {
        let rol = localStorage.getItem('roles');
        //console.log(event);
        if( rol == 'IS_INTERVENANT'){
            this.userService.getStudentByPlanning(event.meta).subscribe(
                data => {

                    this.modalData = { event, action };
                    this.studdents = [];
                    data.forEach(ev => {
                        this.studdents.push(ev);
                    });
                    //console.log(data);

                    this.modal.open(this.modalContent, { size: 'lg' });
                },
                err => {
                    if (err.error instanceof Error) {
                        console.log("Client-side error occured.");
                    } else {
                        console.log("Server-side error occured.");
                    }
                }
            );
        }else{
            this.userService.getTeacherByPlanning(event.meta.planning_id).subscribe(
               data => {
                    if(data[0].matiere == null){
                        this.prof = data[0].nom +' - '+ data[0].prenom
                    }else{
                        this.prof = data[0].nom +' - '+ data[0].prenom +' - '+ data[0].matiere
                    }

               }
            );
           // console.log(event);
            //this.prof = 'youssefi amkhlaw hahahah' + event;
            this.modalData = { event, action };
            this.modal.open(this.modalContent, { size: 'lg' });
        }


    }

    addEvent(): void {
        this.events.push({
            title: 'New event',
            start: startOfDay(new Date()),
            end: endOfDay(new Date()),
            color: colors.red,
            draggable: true,
            resizable: {
                beforeStart: true,
                afterEnd: true
            }
        });
        this.refresh.next();
    }

    getSubjects(n){
        this.userService.getAllSubjects().subscribe( res => {
            //this.subjects = res; console.log(this.subjects); //return;
            let tet:any[] = [];
            res.forEach(function (value) {
                 tet.push({id: value.id, label: value.libelle, code: value.abreviation})
            });
            this.subjects = tet;
            this.loadEvents(n);
            //console.log(this.subjects);
        });
    }


}
