import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ReportEntity} from "../../datas/report-entity";
import {Master, Subject, Notion} from "../pedagogy/pedagogy.component";
import {AlertsComponent} from "../alerts/alerts.component";
import {UserService} from "../../_services/user.service";
import {MatSnackBar} from "@angular/material";
import {IntervenantService} from "../../_services/intervenant.service";
import {Observable} from "rxjs/Rx";
//import {isUndefined} from "util";
import {map, startWith} from "rxjs/operators";




export interface Apprenants {
    id: string;
    first_name: string;
    last_name: string;
}

@Component({
  selector: 'app-add-rapport-dialogue',
  templateUrl: './add-rapport-dialogue.component.html',
  styleUrls: ['./add-rapport-dialogue.component.scss'],
    providers: [UserService, IntervenantService]
})
export class AddRapportDialogueComponent implements OnInit {
    @Input() datas : any;
    selectedApprenant;
    selectedSubject;
    selectedMaster;
    selectedNotion;
    intervenant;
    apprenants;
    newReportForm: FormGroup;
    filteredOptions: Observable<any[]>;
    subjects: Subject[] = [];
    myControl = new FormControl();

    masters : Master[] = [
        {id: 1, label: 'Pas Acquis'},
        {id: 2, label: 'Moyen'},
        {id: 3, label: 'Assez Bien'},
        {id: 4, label: 'Bien '},
        {id: 5, label: 'Acquis'},
    ];
    notions : Notion[] = [
        {id: 1, label: 'Hebdomadaire'},
        {id: 2, label: 'Mensuel'},
    ];
  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              public snackBar: MatSnackBar) {


      this.subjects = [
          {
              id: 5,
              label: "Francais",
              code: "Fr"
          },
          {
              id: 6,
              label: "Mathematiques",
              code: "Math"
          },
          {
              id: 7,
              label: "Physique-Chimie",
              code: "PC"
          },
          {
              id: 8,
              label: "Anglais",
              code: "Ang"
          },
          {
              id: 9,
              label: "Espagnol",
              code: "Esp"
          },
          {
              id: 10,
              label: "Histoire geographie",
              code: "HG"
          },
          {
              id: 12,
              label: "Philosophie",
              code: "Philo"
          },
          {
              id: 13,
              label: "SVT",
              code: "SVT"
          },
          {
              id: 15,
              label: "Conditions generales",
              code: "CG"
          },
          {
              id: 16,
              label: "Economie",
              code: "Economie"
          },
          {
              id: 17,
              label: "Enseignement Moral et Physique ",
              code: "EMC"
          },
          {
              id: 18,
              label: "Numerique Siences Informatique",
              code: "NSI"
          },
          {
              id: 19,
              label: "Biologie, Ecologie ",
              code: "BIO ECOLO"
          },
          {
              id: 20,
              label: "HG - Geopolique et Science Politique ",
              code: "HG - Scienc Po "
          },
          {
              id: 21,
              label: "Sciences de l'ingenieur",
              code: "SI"
          },
          {
              id: 22,
              label: "Science Economique Social ",
              code: "SES"
          },
          {
              id: 25,
              label: "Coworking",
              code: "CW"
          },
          {
              id: 26,
              label: "Orientation",
              code: "Or"
          },
          {
              id: 27,
              label: "Assistance Administrative",
              code: "ADMIN"
          },
          {
              id: 28,
              label: "Recherche Logement",
              code: "LOC"
          },
          {
              id: 29,
              label: "Recherche de Stage",
              code: "RS"
          },
          {
              id: 30,
              label: "Coaching Scolaire",
              code: "CS"
          },
          {
              id: 31,
              label: "Arabe",
              code: "Ar"
          },
          {
              id: 32,
              label: "Cours Primaires",
              code: "CP"
          },
          {
              id: 33,
              label: "Logique",
              code: "LGQ"
          },
          {
              id: 34,
              label: "Culture Generale",
              code: "CG"
          },
          {
              id: 36,
              label: "comptabilitÃ©",
              code: "Compta"
          },
          {
              id: 37,
              label: "PrÃ©pa C",
              code: "PrÃ©paration Concours"
          },
          {
              id: 38,
              label: "Raisonnement/Empathie",
              code: "Communication/Ethique"
          },
          {
              id: 42,
              label: "SAT",
              code: "PrÃ©pa"
          },
          {
              id: 43,
              label: "GMAT",
              code: "PrÃ©pa"
          },
          {
              id: 44,
              label: "IELTS",
              code: "PrÃ©pa"
          },
          {
              id: 45,
              label: "LLCE",
              code: "LLCE"
          },
          {
              id: 46,
              label: "Droit",
              code: "Dt"
          },
          {
              id: 47,
              label: "Concours blanc",
              code: "CB"
          },
          {
              id: 48,
              label: "Managment",
              code: "mgmt"
          },
          {
              id: 49,
              label: "Ã‰co-droit",
              code: "Ã‰-D"
          },
          {
              id: 50,
              label: "Neutre",
              code: "NTR"
          },
          {
              id: 51,
              label: "Grand Oral",
              code: "GO"
          }
      ];

      this.masters  = [
          {id: 1, label: 'Pas Acquis'},
          {id: 2, label: 'Moyen'},
          {id: 3, label: 'Assez Bien'},
          {id: 4, label: 'Bien '},
          {id: 5, label: 'Acquis'},
      ];
      this.notions  = [
          {id: 1, label: 'Hebdomadaire'},
          {id: 2, label: 'Mensuel'},
      ];
  }

  ngOnInit() {
      this.apprenants = this.datas.apprenants;
     /* this.filteredOptions = this.myControl.valueChanges
          .pipe(
              startWith(''),
              map(value => this._filter(value))
          );*/

      this.filteredOptions = this.myControl.valueChanges
          .pipe(
              startWith(''),
              map(value => typeof value === 'string' ? value : value.firstName),
              map(name => name ? this._filter(name) : this.apprenants.slice())
          );

      this.getSubjects();
      this.intervenant = this.datas.intervenant;

      this.newReportForm = this.formBuilder.group({
          date_report: ['', Validators.required],
          description: ['', Validators.required],
          subject: ['', [Validators.required]],
          title: ['', [Validators.required]],
          apprenant: ['', []],
          to_work_on: ['', [Validators.required]],
          //htp: ['', [Validators.required]],
          //hma: ['', [Validators.required]],
          notion: ['', [Validators.required]],
          master: ['', [Validators.required]],
      });

  }

    displayFn(user?: any): string | undefined {
        return user ? user.firstName : undefined;
    }
    getSubjects(){
        //this.userService.getIntervenantSubjects().subscribe( res => {
        //    this.subjects = res;
        //});
    }
    onSubmit(){
        let report = new ReportEntity();
        report.date_report = this.newReportForm.value.date_report;
        report.description = this.newReportForm.value.description;
        report.title = this.newReportForm.value.title;
        report.subject = this.newReportForm.value.subject;
        report.intervenant = this.datas.intervenant;
        report.apprenant = this.selectedApprenant.id;

        report.master = this.selectedMaster.id;
        report.to_work_on = this.newReportForm.value.to_work_on;
        report.htp = this.newReportForm.value.htp;
        report.hma = this.newReportForm.value.hma;
        report.notion = this.selectedNotion.id;

      console.log(report);
      console.log(this.selectedNotion);

        this.userService.newReport(report).subscribe(
            data => {

                this.snackBar.openFromComponent(AlertsComponent,{
                    duration: 1000,
                    panelClass: ['alerts-snackbar-seccus'],
                    data:data
                });

            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            });

    }


    onSearchChange(event){
        this.selectedApprenant = event;
    }
    onSubjectChange(event){
        this.selectedSubject = event.value;
    }
    onMasterChange(event){
        this.selectedMaster = event.value;
    }
    onNotionChange(event){
        this.selectedNotion = event.value;
    }

    private _filter(name: string): any[] {
        const filterValue = name.toLowerCase();

        return this.apprenants.filter(option => option.firstName.toLowerCase().indexOf(filterValue) === 0);
    }
}
