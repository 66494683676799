import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import {FactureEntity} from "../../datas/facture-entity";
import {TableDataSource, ValidatorService} from "angular4-material-table";
import {UserService} from "../../_services/user.service";
import {FactureValidatorService} from "../mes-factures/facture-validator.service";
import {CommandeEntity} from "../../datas/commande-entity";
import {CommandeValidatorService} from "../mes-commandes/commande-validator.service";
import {MesRegelementsComponent} from "../mes-regelements/mes-regelements.component";
@Component({
  selector: 'app-facture-details',
  templateUrl: './facture-details.component.html',
  styleUrls: ['./facture-details.component.scss'],
    providers: [
        {provide: ValidatorService, useClass: CommandeValidatorService },
        UserService
    ],
})
export class FactureDetailsComponent implements OnInit {

    facture;
    facture_cmds;
    current_date = new Date();
    id: number;
    private sub: any;
    @Input() commandeList;
    @Output() commandeListChange = new EventEmitter<any>();
    @ViewChild(MesRegelementsComponent) child;
    displayedColumns = ['date_cmd' , 'year_cmd', 'libelle', 'factured_hours' ,  'reduction_heures' , 'reduction_montant'   , 'qte'  , 'totale' ];
    dataSource: TableDataSource<CommandeEntity>;
    facture_reglement_total:number;

    constructor( private userService: UserService,  private commandeValidator: ValidatorService,private route: ActivatedRoute) { }


  ngOnInit() {
      this.sub = this.route.params.subscribe(params => {
          this.id = +params['id']; // (+) converts string 'id' to a number

          // In a real app: dispatch action to load the details here.
      });
    this.getFacturesDetails();
    //this.getFactureCmds();
  }
    getFacturesDetails(){
        this.userService.getFacturesDetails(this.id).subscribe(
            data => {
                 //return;
                this.facture = data[0];
                //console.log(this.facture);
                this.getFactureCmds();


            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            });
    }

    setDateFacture(date_facture){
        let date = new Date(date_facture *1000);
        let test = date.toLocaleDateString();
        return test;
    }
    getTotalCost() {
        //return this.transactions.map(t => t.cost).reduce((acc, value) => acc + value, 0);
    }

    getFactureCmds(){
        this.userService.getFacturesCmds(this.id).subscribe(
            data => {
                //return;
                //this.facture_cmds = data;
               //console.log(data); return;

                this.displayedColumns = ['date_cmd', 'year_cmd', 'libelle' , 'factured_hours' ,  'reduction_heures' , 'reduction_montant'  ,   'qte'  ,'totale' ];
                // this.dataSource = TableDataSource<CommandeEntity>;
                //return;

                this.commandeList = (data);

                this.dataSource = new TableDataSource<CommandeEntity>(this.commandeList,   CommandeEntity , this.commandeValidator);
                this.dataSource.datasourceSubject.subscribe(commandeList => this.commandeListChange.emit(commandeList));

                this.facture_reglement_total =  this.facture.totale - this.child.total_reglement;

            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            });
    }

}
