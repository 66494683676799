import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import {MatChipInputEvent} from '@angular/material';
import {ProfsEntity} from '../../datas/profs-entity';
import {UserService} from "../../_services/user.service";
import {Subject} from "../../shared/pedagogy/pedagogy.component";
import {JwtHelperService} from "@auth0/angular-jwt";


export interface Matiere {
    name: string;
    value: number;
}

@Component({
  selector: 'app-profs',
  templateUrl: './profs.component.html',
  styleUrls: ['./profs.component.scss'],
    providers: [UserService]

})
export class ProfsComponent implements OnInit {

    visible = true;
    selectable = true;

    mat: Subject;
    intervenants;
    showLoader = true;

    subjects: Subject[] = [
        {id: 0, label: 'Autre', code: 'Autre'},
        {id: 5, label: 'Français', code: 'Fr'},
        {id: 6, label: 'Mathématiques', code: 'Math'},
        {id: 7, label: 'Physique-Chimie', code: 'PC'},
        {id: 8, label: 'Anglais', code: 'EN'},
        {id: 9, label: 'Espagnol', code: 'ES'},
        {id: 10, label: 'Histoire géographie', code: 'HG'},
        {id: 11, label: 'Français Langue Etrangère', code: 'FLE'},
        {id: 12, label: 'Philosophie', code: 'PHILO'},
        {id: 13, label: 'SVT', code: 'SVT'},
    ];

    profs = [];
    user;

  constructor(private userService: UserService) { }

  ngOnInit() {
      this.getProfs();
  }


    /*getLogedUser(){
        if (localStorage.getItem('currentUser')) {
            const jwthelper = new JwtHelperService();
            let current_user = jwthelper.decodeToken(localStorage.getItem('currentUser'));
            this.userService.getUserByUsername(current_user['username']).subscribe( res => {
                console.log(res);
                if(res != 0 ){
                    this.user = res[0].id;
                    this.getIntervenants(this.user);
                }

            });


        }else{
            console.log('je crois que vous n\'etes pas connecté , merci de checker, sinon contactez l\'administration')
        }
    }*/

    getProfs(){
        this.userService.getProfs().subscribe(
            data => {
                this.profs = data;
               //console.log(data);
               //return;
                let int = [];
                let userid = null;
                this.intervenants = int;
                //console.log(this.intervenants);
                this.showLoader = false;
            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            });
    }


    onChange(item) {
      //console.log(item);
      this.mat = item;
    }



}
