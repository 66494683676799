import {AfterViewChecked, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CommandeEntity} from "../../datas/commande-entity";
import {TableDataSource, ValidatorService} from "angular4-material-table";
import {MesRegelementsComponent} from "../mes-regelements/mes-regelements.component";
import {UserService} from "../../_services/user.service";
import {CommandeValidatorService} from "../mes-commandes/commande-validator.service";

@Component({
  selector: 'app-facture-details-print',
  templateUrl: './facture-details-print.component.html',
  styleUrls: ['./facture-details-print.component.scss'],
    providers: [
        {provide: ValidatorService, useClass: CommandeValidatorService },

    ],
})
export class FactureDetailsPrintComponent implements AfterViewChecked {

  @Input() facture;
  @Input() cmds;
  @Input() regle;
    current_date = new Date();
    //dataSource;
    @Input() commandeList;
    @Output() commandeListChange = new EventEmitter<any>();


    displayedColumns = ['libelle', 'qte' , 'reduction_montant'  , 'totale' ];
    dataSource: TableDataSource<CommandeEntity>;
    facture_reglement_total:number;
    total_reglement : number;

  constructor( private commandeValidator: ValidatorService) { }

    ngAfterViewChecked() {
      //console.log(); return;
      //this.commandeList = this.cmds;
      this.dataSource = this.cmds;
      //this.dataSource.datasourceSubject.subscribe(commandeList => this.commandeListChange.emit(commandeList));
    this.total_reglement = this.regle;
      this.facture_reglement_total =  this.facture.totale - this.regle;

  }
    setDateFacture(date_facture){
        let date = new Date(date_facture *1000);

        let test = date.toLocaleDateString();
        return test;
    }

}
