import {Master, Subject} from "../shared/pedagogy/pedagogy.component";

export class PedagogyEntity {
    date_suivi: Date;
    chapter: string;
    master?: Master  ;
    to_work_on: string;
    comment: string;
    htp: string;
    hma: number;
    subject?: Subject ;
}

export class MasterChapter {
    id: number;
    label: string;
}