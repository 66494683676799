export class ReportEntity {
    public id: number;
    public date_report: Date;
    public title: string;
    public description: string;
    public subject: number;
    public to_work_on: string;
    public htp?: string;
    public hma?: number;
    public master?: number;
    public intervenant?: number;
    public apprenant?: number;
    public notion?: number;

}
