import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

import {UserService} from "../../_services/user.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {MatPaginator, MatTableDataSource} from "@angular/material";



@Component({
  selector: 'app-list-apprenants',
  templateUrl: './list-apprenants.component.html',
  styleUrls: ['./list-apprenants.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    providers: [
        UserService
    ]
})

export class ListApprenantsComponent implements OnInit {
    //dataSource = [];
    dataSource = new MatTableDataSource();

    // columnsToDisplay = ['Nom', 'Prénom', 'Cycle', 'Niveau',  'Remarque', 'Matiére', 'Séance']; 'Cycle','cycle',
    columnsToDisplay = [' ', 'Nom', 'Prénom','séance','Détails'];
    listApprenantToDisplay = ['sexe' ,'first_name', 'last_name', 'seance','det'];
    expandedElement: PeriodicElement;
    user;
    apprenants;
    showLoader = true;
    token;
    current_user;user_type;
    annee ; anneep;
  constructor(private userService: UserService) { }
    @Input() currentState;
    @ViewChild(MatPaginator) paginator: MatPaginator;
  ngOnInit() {
        this.getYear();
      this.getLogedUser();
  }

    getLogedUser(){
        if (localStorage.getItem('currentUser')) {
            const jwthelper = new JwtHelperService();
            this.current_user = jwthelper.decodeToken(localStorage.getItem('currentUser'));
            //console.log(this.current_user);
            localStorage.getItem('role');
            if( localStorage.getItem('roles') == 'IS_INTERVENANT'){
                //console.log('yeeeh');
                this.user_type = 1;
                this.getStudents();
            }else{
                //console.log('noooo');
                this.user_type = 0; //appr
                //this.getIntervenants(this.user);
            }
        }else{
            console.log('je crois que vous n\'etes pas connecté , merci de checker, sinon contactez l\'administration')
        }
    }

    getStudents(){
        this.userService.getApprenantsByIntervenantFull().subscribe( res => {
            //console.log(res);

            this.dataSource = new  MatTableDataSource(res);
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = (data : PeriodicElement, filter) =>
                (data.first_name.trim().toLowerCase().indexOf(filter) !== -1 ||
                    data.last_name.trim().toLowerCase().indexOf(filter) !== -1 );
            //return;
            this.showLoader = false;
        });
    };
    applyFilter(filterValue: string) {
        //console.log('yapp');
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    getYear(){
        this.userService.getThisYear().subscribe( res => {

            let t = parseInt((res[0].libelle.split('-')[0]).substr(2,4));
            let t2 = parseInt((res[0].libelle.split('-')[1]).substr(2,4));
            this.annee = t +'/'+ t2;
            this.anneep = (t - 1) +'/'+ (t2 -1);
        });
    }

    getApprenantsByIntervenant(id){

        this.userService.getApprenantsByIntervenant().subscribe( res => {
            let int = [];
            let userid = null;
            //let int1 = [];
            let int1 = [];
            //res = [];

            for(let d of res){
                if((d.apprenants.length == 0)){

                }else {

                    if(userid == null){
                        int.push(d.apprenants[0].user);
                        userid = d.apprenants[0].user.id;
                        int1.push({
                            id: d.apprenants[0].user.id,
                            first_name: d.apprenants[0].user.first_name,
                            last_name: d.apprenants[0].user.last_name,
                            level: d.apprenants[0].user.level.label,
                            cycle: d.apprenants[0].user.cycle.label,
                            comment: (d.apprenants[0].user.comment).substring(0, 10),
                            birthday: d.apprenants[0].user.birthday,
                            register_at: d.apprenants[0].user.register_at,
                            sexe: d.apprenants[0].user.sexe.label,
                            user_pap_name: d.apprenants[0].user.user_pap_name,
                            user_tur_name: d.apprenants[0].user.user_tur_name,
                            user_tur_linked_by: d.apprenants[0].user.user_tur_linked_by,
                        });
                    }else if(d.apprenants[0].user.id == userid){

                    }else{
                        int.push(d.apprenants[0].user);
                        int1.push({
                            id: d.apprenants[0].user.id,
                            first_name: d.apprenants[0].user.first_name,
                            last_name: d.apprenants[0].user.last_name,
                            level: d.apprenants[0].user.level.label,
                            cycle: d.apprenants[0].user.cycle.label,
                            comment: (d.apprenants[0].user.comment).substring(0, 10),
                            birthday: d.apprenants[0].user.birthday,
                            register_at: d.apprenants[0].user.register_at,
                            sexe: d.apprenants[0].user.sexe.label,
                            user_pap_name: d.apprenants[0].user.user_pap_name,
                            user_tur_name: d.apprenants[0].user.user_tur_name,
                            user_tur_linked_by: d.apprenants[0].user.user_tur_linked_by,
                        });

                        userid = d.apprenants[0].user.id;
                    }
                }
            }

            // this.apprenants = int;
            this.dataSource = new MatTableDataSource(int1);

            this.showLoader = false;

        });
    };

    toggle(){

    }
    onClick(data){
        if (data == this.expandedElement){
            this.currentState = 'collapsed';
        }else{
            this.currentState = 'expanded';

        }
    }

}
export interface PeriodicElement {
    id: number,
    first_name: string;
    last_name: string;
    level: Object;
    speciality: string;
    comment: string;
    subject: Object;
    session: string;
    seance: string;
}


