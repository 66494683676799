import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from "@angular/material";
import {DialogData} from "../show-pedagogy-dialogue/show-pedagogy-dialogue.component";
import {UserService} from "../../_services/user.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AlertsComponent} from "../alerts/alerts.component";
import {PedagogyEntity} from "../../datas/pedagogy-entity";
import {Master, Subject} from "../pedagogy/pedagogy.component";

export interface DialogData {
    subjects: Subject;
    masters: Master;
    user: string;
    row:any;
}

@Component({
  selector: 'app-show-pedagogy-dialogue',
  templateUrl: './show-pedagogy-dialogue.component.html',
  styleUrls: ['./show-pedagogy-dialogue.component.scss'],
    providers: [
        UserService
    ]
})
export class ShowPedagogyDialogueComponent implements OnInit {
    subjects = this.data.subjects;
    masters = this.data.masters;
    user = this.data.user;
    row = this.data.row;
    selectedSubject;
    selectedMaster;

    @Output() pedagogyAddedShow: EventEmitter<any> = new EventEmitter();

    //newPedagogy: FormGroup;
    newPedagogy = new FormGroup ({
        date_suivi: new FormControl({disabled:true}),
        chapter: new FormControl({disabled:true}),
        htp: new FormControl({disabled:true}),
        hma: new FormControl({disabled:true}),
        master: new FormControl({disabled:true}),
        subject: new FormControl({disabled:true}),
        to_work_on: new FormControl({disabled:true}),
        comment: new FormControl({disabled:true}),
    });
    constructor(
        public dialogRef: MatDialogRef<ShowPedagogyDialogueComponent>,
        @Inject(MAT_DIALOG_DATA) private data: DialogData,
        private formBuilder: FormBuilder,
        private userService: UserService,
        public snackBar: MatSnackBar
    ) {

    }

    ngOnInit() {
        this.newPedagogy = this.formBuilder.group({
            date_suivi: [this.row.date_suivi,  Validators.required],
            chapter: [this.row.chapter, Validators.required],
            htp: [this.row.htp, Validators.required],
            hma: [this.row.hma, Validators.required],
            master: [this.row.master.id, Validators.required],
            subject: [this.row.subject, Validators.required],
            to_work_on: [this.row.to_work_on, Validators.required],
            comment: [this.row.mentor_comment, Validators.required],
        });


    }

    onSubjectChange(event){
        this.selectedSubject = event.value;
    }
    onMasterChange(event){
        this.selectedMaster = event.value;
    }
}
