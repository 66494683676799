import { Component, OnInit } from '@angular/core';
import {UserService} from "../../_services/user.service";

@Component({
  selector: 'app-suivi',
  templateUrl: './suivi.component.html',
  styleUrls: ['./suivi.component.scss']
})
export class SuiviComponent implements OnInit {
    panelOpenState;
    year;
  constructor(private userService: UserService) { }

  ngOnInit() {
    this.getScolarityYear()
  }

    getScolarityYear(){

        this.userService.getThisYear().subscribe(

            data => {
                this.year = data[0].libelle;
            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            });
    }

}
