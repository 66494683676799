declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/apprenant/', title: 'Table de Board',  icon: 'dashboard', class: '' },
    { path: '/apprenant/profil', title: 'Mon Profil',  icon:'account_circle', class: '' },
    { path: '/apprenant/suivi', title: 'Suivi pédagogique',  icon:'chrome_reader_mode', class: '' },
    { path: '/apprenant/conversations', title: 'Conversation',  icon:'inbox', class: '' },
    { path: '/apprenant/planning', title: 'Mon Planning',  icon:'calendar_today', class: '' },
    { path: '/apprenant/professeurs', title: 'Mes Professeurs',  icon:'people', class: '' },
    { path: '/apprenant/contact', title: 'Administration',  icon:'mail', class: '' },
    { path: '/apprenant/facture', title: 'Factures / CMD',  icon:'library_books', class: '' },
];
