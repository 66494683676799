import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {SharedContactComponent} from './shared-contact/shared-contact.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {
     MatBottomSheetModule,
    MatButtonModule, MatCardModule,
    MatChipsModule,
    MatDatepickerModule, MatDialogModule, MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatTooltipModule,MatAutocompleteModule,
    MatNativeDateModule, MatOptionModule,
    MatSelectModule, MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTableModule, MatTabsModule,
    MatToolbarModule,
    MatPaginatorModule
} from '@angular/material';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {CalendarComponent} from './calendar/calendar.component';
import {ChatComponent} from './chat/chat.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import { ListApprenantsComponent } from './list-apprenants/list-apprenants.component';
import {ScolarityComponent} from './scolarity/scolarity.component';
import {PedagogyComponent} from './pedagogy/pedagogy.component';
import {NotesComponent} from './notes/notes.component';
import {NewReportModal, RapportComponent} from './rapport/rapport.component';
import { AddRapportDialogueComponent } from './add-rapport-dialogue/add-rapport-dialogue.component';
import { AlertsComponent } from './alerts/alerts.component';
import { AddControlDialogueComponent } from './add-control-dialogue/add-control-dialogue.component';
import { EditUploadControlDialogueComponent } from './edit-upload-control-dialogue/edit-upload-control-dialogue.component';
import { AddBulletinDialogueComponent } from './add-bulletin-dialogue/add-bulletin-dialogue.component';
import { AddPedagogyDialogueComponent } from './add-pedagogy-dialogue/add-pedagogy-dialogue.component';
import { AddScolarityDialogueComponent } from './add-scolarity-dialogue/add-scolarity-dialogue.component';
import { ShowPedagogyDialogueComponent } from './show-pedagogy-dialogue/show-pedagogy-dialogue.component';
import { AddNewControlDialogueComponent } from './add-new-control-dialogue/add-new-control-dialogue.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatChipsModule,
        MatListModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        MatOptionModule,
        FormsModule,
        MatExpansionModule,
        MatTableModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        MatListModule,
        MatChipsModule,
        MatCardModule,
        MatToolbarModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatMenuModule,
        NgbModalModule,
        MatTabsModule,
        MatDialogModule,
        MatBottomSheetModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatAutocompleteModule


    ],
    declarations: [
        SharedContactComponent,
        CalendarComponent,
        ChatComponent,
        ListApprenantsComponent,
        ScolarityComponent,
        PedagogyComponent,
        NotesComponent,
        RapportComponent,
        AddRapportDialogueComponent,
        NewReportModal,
        AlertsComponent,
        AddControlDialogueComponent,
        EditUploadControlDialogueComponent,
        AddBulletinDialogueComponent,
        AddPedagogyDialogueComponent,
        AddScolarityDialogueComponent,
        ShowPedagogyDialogueComponent,
        AddNewControlDialogueComponent,

    ],
    exports: [
        SharedContactComponent,
        CalendarComponent,
        ChatComponent,
        ListApprenantsComponent,
        ScolarityComponent,
        PedagogyComponent,
        NotesComponent,
        RapportComponent,
        AddRapportDialogueComponent,
        NewReportModal,
        AlertsComponent,
        AddControlDialogueComponent,
        AddNewControlDialogueComponent,
        EditUploadControlDialogueComponent,
        AddBulletinDialogueComponent,
        AddPedagogyDialogueComponent,
        AddScolarityDialogueComponent,
        ShowPedagogyDialogueComponent,
        MatTooltipModule,
        MatPaginatorModule,
    ],
    entryComponents: [
        AddRapportDialogueComponent,
        NewReportModal,
        AlertsComponent,
        AddControlDialogueComponent,
        EditUploadControlDialogueComponent,
        AddBulletinDialogueComponent,
        AddPedagogyDialogueComponent,
        AddScolarityDialogueComponent,
        ShowPedagogyDialogueComponent,
        AddNewControlDialogueComponent


    ]
})
export class SharedModule {  }
