import {Component, OnInit} from '@angular/core';
import {UserService} from "../../_services/user.service";
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ChatEntity} from "../../datas/chat-entity";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Observable} from "rxjs/Rx";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
    providers: [UserService]
})
export class ChatComponent  implements OnInit {

  them;  user;   sidePanelOpened = true;
  msjuser;   intervenants;   user_type;
  showLoader = true;
  // MESSAGE
  selectedMessage: any;
  hided: number ;

  messages ;
  show = true;

    chatForm = new FormGroup ({
        content: new FormControl()
    });
    current_user;inssibcrb;

  constructor(private userService: UserService, private fb: FormBuilder) {
    this.selectedMessage = [];
      this.chatForm = this.fb.group({
          content: ["", Validators.required ],
      });
  }

    ngOnInit() {
        this.selectedMessage['brochure'] = 'default.png';
        this.getLogedUser();
    }


    getLogedUser(){
        if (localStorage.getItem('currentUser')) {
            const jwthelper = new JwtHelperService();
            this.current_user = jwthelper.decodeToken(localStorage.getItem('currentUser'));
            let rol = localStorage.getItem('roles');
            if( rol == 'IS_INTERVENANT'){
                this.user_type = 1;
                this.msjuser = 'Séléctionner un Apprenant à contacter';
                this.getStudents();
            }else{
                this.user_type = 0; //appr
                this.msjuser =  'Séléctionner un Intervenant à contacter';

               this.getTeachers(this.user_type);
            }


        }else{
            console.log('je crois que vous n\'etes pas connecté , merci de checker, sinon contactez l\'administration')
        }
    }

    getTeachers(id){
        this.userService.getProfs().subscribe( res => {
            this.intervenants = res;
            this.showLoader = false;
        });
    };

    getStudents(){
        this.userService.getApprenantsByIntervenant().subscribe( res => {

            this.intervenants = JSON.parse(res);
            this.showLoader = false;
        });
    };

  isOver(): boolean {
    return window.matchMedia(`(max-width: 960px)`).matches;
  }

  onSelect(message: Object[]): void {
    this.selectedMessage = message;
    this.show = false;
    this.getMessages(message, this.user_type);
     /* if(this.inssibcrb){
          this.inssibcrb.unsubscribe();
      }
      this.inssibcrb = Observable.interval(1000 * 60).subscribe(x => {
          this.getMessages(message, this.user_type);
      });*/


  }
    getApprenantsByIntervenant(id:number = null){
        this.userService.getApprenantsByIntervenant().subscribe( res => {
            let int = [];
            let userid = null;
            //let int1 = [];
            let int1 = [];
            //res = [];
            for(let d of res){
                if((d.apprenants.length == 0)){

                }else {

                    if(userid == null){
                        int.push(d.apprenants[0].user);
                        userid = d.apprenants[0].user.id;
                        int1.push({
                            id: d.apprenants[0].user.id,
                            first_name: d.apprenants[0].user.first_name,
                            last_name: d.apprenants[0].user.last_name,
                            level: d.apprenants[0].user.level.label,
                            cycle: d.apprenants[0].user.cycle.label,
                            comment: d.apprenants[0].user.comment,
                            birthday: d.apprenants[0].user.birthday,
                            register_at: d.apprenants[0].user.register_at,
                            sexe: d.apprenants[0].user.sexe.label,
                            user_pap_name: d.apprenants[0].user.user_pap_name,
                            user_tur_name: d.apprenants[0].user.user_tur_name,
                            user_tur_linked_by: d.apprenants[0].user.user_tur_linked_by,
                        });
                    }else if(d.apprenants[0].user.id == userid){

                    }else{
                        int.push(d.apprenants[0].user);
                        int1.push({
                            id: d.apprenants[0].user.id,
                            first_name: d.apprenants[0].user.first_name,
                            last_name: d.apprenants[0].user.last_name,
                            level: d.apprenants[0].user.level.label,
                            cycle: d.apprenants[0].user.cycle.label,
                            comment: d.apprenants[0].user.comment,
                            birthday: d.apprenants[0].user.birthday,
                            register_at: d.apprenants[0].user.register_at,
                            sexe: d.apprenants[0].user.sexe.label,
                            user_pap_name: d.apprenants[0].user.user_pap_name,
                            user_tur_name: d.apprenants[0].user.user_tur_name,
                            user_tur_linked_by: d.apprenants[0].user.user_tur_linked_by,
                        });

                        userid = d.apprenants[0].user.id;
                    }
                }
            }
            // this.apprenants = int;
            this.intervenants = int1;

            this.showLoader = false;

        });
    };
    getIntervenants(id:number){
        this.userService.getIntervenantsMessages(id).subscribe(
            data => {
                this.intervenants = data;
                let int = [];
                let userid = null;
                for(let d of this.intervenants){
                    if(d.intervenant.user.id == null){
                        int.push(d.intervenant.user);
                        userid = d.intervenant.user.id;
                    }else if(d.intervenant.user.id == userid){

                    }else{
                        int.push(d.intervenant.user);
                        userid = d.intervenant.user.id;
                    }

                }
                this.intervenants = int;
                this.showLoader = false;
            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            });
    }


    getMessages(message,type_user){

        this.messages = Observable.interval(1000).startWith(0)
            .switchMap(() => this.userService.getMessages(type_user, message.id));

        /*this.userService.getMessages(type_user, message.id).subscribe(
            data => {
                console.log(data);
                this.messages = data;

            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            });*/

        //this.setMessagesAsReaded(this.user_type,message.id);

    }
    setMessagesAsReaded(type_user,msj){
        this.userService.setMessageAsReaded(type_user,msj).subscribe(
            data => {
                //console.log(data);
            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            });
    }



    addMessage(to){
      let chat = new ChatEntity();
      let content = this.chatForm.value.content;
      let app;
      chat.content = content;

        if( this.user_type == 1){
            chat.from_sender = 'them';
            chat.intervenant = null;
            chat.user = to;
        }else if(this.user_type == 0){
            chat.from_sender = 'me';
            chat.intervenant = to;
            chat.user = null;
        }

        //console.log(chat); //return;

       this.userService.addNewMessage(chat).subscribe(
            data => {
                //this.messages = data;
                this.chatForm.reset();
                //this.getMessages(chat.intervenant, 3);
                //this.messages.push(chat);
                let rol = localStorage.getItem('roles');
                if( rol == 'IS_INTERVENANT'){
                    this.getStudents();
                }else{
                    this.getTeachers(this.user_type);
                }
            },
            err => {
                if (err.error instanceof Error) {
                    console.log("Client-side error occured.");
                } else {
                    console.log("Server-side error occured.");
                }
            });

    }

    getReader(m){
        let rol = localStorage.getItem('roles');
        if( rol == 'IS_INTERVENANT'){
            if(m.vu_intervenant){
                return 0;
            }else{
                return 1;
            }
        }else{
            if(m.vu_apprenant){
                return 0;
            }else{
                return 1;
            }
        }

    }
}
