declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/intervenant/', title: 'Table de Board',  icon: 'dashboard', class: '' },
    { path: '/intervenant/apprenants', title: 'Apprenants',  icon:'list', class: '' },
    { path: '/intervenant/planning', title: 'Mon Planning',  icon:'calendar_today', class: '' },
    { path: '/intervenant/conversations', title: 'Conversations',  icon:'question_answer', class: '' },
    { path: '/intervenant/rapports', title: 'Mes Rapports',  icon:'speaker_notes', class: '' },
    { path: '/intervenant/contact', title: 'Administration',  icon:'mail', class: '' },
];
