import {Component, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import {UserService} from "./user.service";


@Injectable({
  providedIn: 'root',

})
export class AuthenticationService {


  constructor(private http:HttpClient) { }

  login(email: string, password: string){

      return this.http.post<any>('https://api.az-educ.fr/api/login_check', { email, password })
      //return this.http.post<any>('http://127.0.0.1:8000/api/login_check', { email, password })
          .pipe(map(user => {
                console.log('okkk');
              // login successful if there's a jwt token in the response
              if (user && user.token) {
                  console.log('sss');
                  // store user details and jwt token in local storage to keep user logged in between page refreshes
                  const jwthelper = new JwtHelperService();
                  localStorage.setItem('currentUser', JSON.stringify(user));
                  localStorage.setItem('token', user.token);
                  let currentUser = localStorage.getItem('currentUser');
                   let datas = jwthelper.decodeToken(currentUser);
                   localStorage.setItem('roles',datas['roles'][0]);
                  let current_user = jwthelper.decodeToken(localStorage.getItem('currentUser'));
                   localStorage.setItem('username',current_user['username']);


              }

              return user;
          }));

  }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('roles');
        localStorage.removeItem('token');
        localStorage.clear();
    }

    public getToken(): string {
        return localStorage.getItem('token');
    }
}
