export class CommandeEntity {
    public id :number;
    public date_cmd : string;
    public factured_hours : string;
    public libelle : string;
    public year_cmd : string;
    public nfacture: string;
    public prix: string;
    public reduction_heures: string;
    public reduction_montant: string;
    public volume: string;
    public totale: string;
    public taux_conso: string;
}
