import { LOCALE_ID, Inject } from '@angular/core';
import { CalendarEventTitleFormatter, CalendarEvent } from 'angular-calendar';
import { DatePipe } from '@angular/common';


export class NgxEventTitleFormatterProvider extends CalendarEventTitleFormatter {
    constructor(@Inject(LOCALE_ID) private locale: string) {
        super();
    }

    // you can override any of the methods defined in the parent class

    month(event: CalendarEvent): string {
        return `${event.title}`;
    }

    week(event: CalendarEvent): string {
        let str: string = '';
        for (const entry of event.meta.students) {
            str = str.concat('<li>');
            str = str.concat((entry[0].nom).substr(0,1) + '.' + entry[0].prenom +' '+ event.meta.matiere.name);
            str = str.concat('</li>');
        }
        return `<div class="card-block ${event.meta.students.color}">${event.title} <br><ul class="nav list-inline ml-auto ">` + str + `</ul></div>`
        ;
    }

    day(event: CalendarEvent): string {
        return `${event.title}`;
    }
}