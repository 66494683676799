import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import {ApprenantProfile} from "../datas/apprenant-profile";
import {ScolarityEntity, YearEntity} from "../datas/scolarity-entity";
import {PedagogyEntity} from "../datas/pedagogy-entity";
import {NotesEntity} from "../datas/notes-entity";
import {ControlEntity} from "../datas/control-entity";
import {BulletinEntity} from "../datas/bulletin-entity";
import {ChatEntity} from "../datas/chat-entity";
import {ContactEntity} from "../datas/contact-entity";
import {PlanningEntity} from "../datas/planning-entity";
import {CalendarEvent} from "angular-calendar";
import {ReportEntity} from "../datas/report-entity";
import {JwtHelperService} from "@auth0/angular-jwt";
import {JwtInterceptor} from "../_helpers/jwt.interceptor";
import {ProfsEntity} from "../datas/profs-entity";
import {MoyenneEntity} from "../datas/moyenne-entity";


//const endpoint = 'http://127.0.0.1:8000/api/';
const endpoint = 'https://api.az-educ.fr/api/';

let httpOptions = {
    headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Cache-Control':  'no-cache, no-store',

    })
};
@Injectable()
export class UserService {

    constructor(private http: HttpClient){

    }



    /**
     * Get Commandes
     * @returns {Observable<any>}
     */
    getCmds(): Observable<any> {
        return this.http.get<any>(endpoint + 'apprenant/cmds', httpOptions)
            .pipe(
                catchError(this.handleError('Apprenants CMD is : '))
            );
    }

    /**
     * Get Commandes
     * @returns {Observable<any>}
     */
    getCmdsWithPlanning(): Observable<any> {
        return this.http.get<any>(endpoint + 'apprenant/mes-cmds', httpOptions)
            .pipe(
                catchError(this.handleError('Apprenants MES CMD is : '))
            );
    }



    /**
     * Get Factures
     * @returns {Observable<any>}
     */
    getFactures(): Observable<any> {
        return this.http.get<any>(endpoint + 'apprenant/factures', httpOptions)
            .pipe(
                catchError(this.handleError('Factures CMD is : '))
            );
    }

    /**
     * Get Facture Details
     * @returns {Observable<any>}
     */
    getFacturesDetails(id): Observable<any> {
        return this.http.get<any>(endpoint + 'apprenant/factures/'+id, httpOptions)
            .pipe(
                catchError(this.handleError('Factures Details CMD is : '))
            );
    }

    /**
     * Get Facture Details Command
     * @returns {Observable<any>}
     */
    getFacturesCmds(id): Observable<any> {

        return this.http.get<any>(endpoint + 'apprenant/factures/'+ id +'/cmds', httpOptions)
            .pipe(
                catchError(this.handleError('Factures Details CMD is : '))
            );
    }

    /**
     * Get Facture Reglements
     * @returns {Observable<any>}
     */
    getFactureReglements(id): Observable<any> {

        return this.http.get<any>(endpoint + 'apprenant/factures/'+ id +'/reglements', httpOptions)
            .pipe(
                catchError(this.handleError('Factures Reg CMD is : '))
            );
    }

    /**
     * Get Students By Teacher
     * @returns {Observable<any>}
     */
    getApprenantsByIntervenant(): Observable<any> {
        return this.http.get<any>(endpoint + 'intervenant/students', httpOptions)
            .pipe(
                catchError(this.handleError('Apprenants list is : '))
            );
    }

    /**
     * Get Students By Teacher
     * @returns {Observable<any>}
     */
    getApprenantsByIntervenantReports(): Observable<any> {
        return this.http.get<any>(endpoint + 'intervenant/students-reports', httpOptions)
            .pipe(
                catchError(this.handleError('Apprenants list is : '))
            );
    }


    /**
     * Get Students By Planning
     * @returns {Observable<any>}
     */
    getStudentByPlanning(id): Observable<any> {
        let data = {planning: id.planning, dd: id.crenaux.debut, df: id.crenaux.fin};
        return this.http.get<any>(endpoint + 'intervenant/plannings/'+id.planning+'/student', {params: data})
            .pipe(
                catchError(this.handleError('Planning Students ERROR'))
            );
    }

    /**
     * Get Students By Planning
     * @returns {Observable<any>}
     */
    getTeacherByPlanning(id_planning): Observable<any> {
        return this.http.get<any>(endpoint + 'intervenant/planning/'+id_planning+'/prof', httpOptions)
            .pipe(
                catchError(this.handleError('Planning Prof ERROR'))
            );
    }


    /**
     * Get Students By Teacher FULL
     * @returns {Observable<any>}
     */
    getApprenantsByIntervenantFull(): Observable<any> {
        return this.http.get<any>(endpoint + 'intervenant/apprenants', httpOptions)
            .pipe(
                catchError(this.handleError('Apprenants list is : '))
            );
    }

    /**
     * Get Teachers By Student
     * @returns {Observable<any>}
     */
    getIntervenantsByApprenant(id): Observable<any> {
        let data = {u: id};

        return this.http.get<any>(endpoint + 'apprenant/intervenants', {params: data})
            .pipe(
                catchError(this.handleError('Intervenant list is : '))
            );
    }



    /*getUser(id): Observable<any> {
        return this.http.get<ApprenantProfile>(endpoint + 'apprenant/show/'+ id, httpOptions)
            .pipe(
                catchError(this.handleError('User '))
            );
    }*/

    getProfile(): Observable<any> {
        return this.http.get<ApprenantProfile>(endpoint + 'apprenant/show/profil', httpOptions)
            .pipe(
                catchError(this.handleError('User '))
            );
    }

    getProfileUser(): Observable<any> {
        return this.http.get<ApprenantProfile>(endpoint + 'apprenant/show/profil-user', httpOptions)
            .pipe(
                catchError(this.handleError('User '))
            );
    }
    getProfileX(x): Observable<any> {
        let data = {u: x};
        return this.http.get<ApprenantProfile>(endpoint + 'apprenant/show/profilx', {params: data})
            .pipe(
                catchError(this.handleError('User '))
            );
    }


    getUserRole(): Observable<any> {
        return this.http.get<any>(endpoint + 'apprenant/profil-roles', httpOptions)
            .pipe(
                catchError(this.handleError('User '))
            );
    }

    getIntervenantSubjects(): Observable<any> {
        return this.http.get<any>(endpoint + 'intervenant/subjects/show', httpOptions)
            .pipe(
                catchError(this.handleError('SUBJECTS ERROR '))
            );
    }




    getUserByUsername(id): Observable<any> {
        return this.http.get<ApprenantProfile>(endpoint + 'apprenant/show-by-username/'+ id, httpOptions)
            .pipe(
                catchError(this.handleError('User '))
            );
    }


    getEventsIntervenants(): Observable<any> {
        return this.http.get<any>(endpoint + 'intervenant/plannings/show', httpOptions)
            .pipe(
                catchError(this.handleError('User '))
            );
    }

    getEventsApprenants(): Observable<any> {
        return this.http.get<any>(endpoint + 'apprenant/plannings/show', httpOptions)
            .pipe(
                catchError(this.handleError('User '))
            );
    }

    updateUser (id, user:ApprenantProfile): Observable<ApprenantProfile> {
        return this.http.put<ApprenantProfile>(endpoint + 'apprenant/edit', user, httpOptions)
            .pipe(
                catchError(this.handleError('updateHero', user))
            );
    }



    getScolarity(id): Observable<any> {
        return this.http.get<ScolarityEntity>(endpoint + 'apprenant/show/'+ id + '/scolarity', httpOptions)
            .pipe(
                catchError(this.handleError('APPRENANT SHOW SCOLARITY ERROR '))
            );
    }

    getYears(): Observable<any> {
        return this.http.get<YearEntity>(endpoint + 'apprenant/years', httpOptions)
            .pipe(
                catchError(this.handleError('APPRENANT SHOW YEAR ERROR '))
            );
    }

    getThisYear(): Observable<any> {
        return this.http.get<any>(endpoint + 'apprenant/year', httpOptions)
            .pipe(
                catchError(this.handleError('APPRENANT SHOW YEAR ERROR '))
            );
    }

    updateScolarity (id, scolarity): Observable<ScolarityEntity> {
        return this.http.put<ScolarityEntity>(endpoint + 'scolarities/edit/' + id + '/scolarity', scolarity, httpOptions)
            .pipe(
                catchError(this.handleError('updateHero', scolarity))
            );
    }
    newScolarity (id, scolarity): Observable<ScolarityEntity> {
        return this.http.put<ScolarityEntity>(endpoint + 'scolarities/new/' + id + '/scolarity', scolarity, httpOptions)
            .pipe(
                catchError(this.handleError('newScolarity', scolarity))
            );
    }
    deleteScolarity (id): Observable<any> {
        return this.http.delete(endpoint + 'scolarities/delete/' + id + '/scolarity', httpOptions)
            .pipe(
                catchError(this.handleError('deleteScolarity',''))
            );
    }


    deleteControl (id): Observable<any> {
        return this.http.delete(endpoint + 'controls/' + id + '/delete', httpOptions)
            .pipe(
                catchError(this.handleError('deleteControl',''))
            );
    }

    getSuivi(id): Observable<any> {
        return this.http.get<ScolarityEntity>(endpoint + 'apprenant/show/'+ id + '/suivi', httpOptions)
            .pipe(
                catchError(this.handleError('User '))
            );
    }



    updateSuivi (id, suivi): Observable<PedagogyEntity> {
        return this.http.put<PedagogyEntity>(endpoint + 'suivis/edit/' + id + '/suivi', suivi, httpOptions)
            .pipe(
                catchError(this.handleError('updateHero', suivi))
            );
    }

    newSuivi (id, suivi): Observable<PedagogyEntity> {
        return this.http.put<PedagogyEntity>(endpoint + 'suivis/new/' + id + '/suivi', suivi, httpOptions)
            .pipe(
                catchError(this.handleError('ADD NEW SUIVI ERROR', suivi))
            );
    }
    deleteSuivi (id): Observable<any> {
        return this.http.delete(endpoint + 'suivis/delete/' + id + '/suivi', httpOptions)
            .pipe(
                catchError(this.handleError('DELETE  SUIVI ERROR',''))
            );
    }

    getUserIntervenants(id): Observable<any> {
        return this.http.get<any>(endpoint + 'apprenant/show/'+ id + '/mentors', httpOptions)
            .pipe(
                catchError(this.handleError('User Notes'))
            );
    }

    getIntervenantsMessages(id): Observable<any> {
        return this.http.get<any>(endpoint + 'apprenant/show/'+ id + '/mentors-messages', httpOptions)
            .pipe(
                catchError(this.handleError('User Notes'))
            );
    }

    /**
     * Get Conversation Intervenant/Apprenant
     *
     */
    getMessages(role , user): Observable<any> {
        let data = {t: role, u: user};
        return this.http.get(endpoint + 'messages/show' ,{params: data})
    }
    /**
     * Get Conversation Intervenant/Apprenant
     *
     */
    setMessageAsReaded(role , user): Observable<any> {
        let data = {t: role, u: user};
        return this.http.post(endpoint + 'messages/close' ,data,httpOptions)
    }


    /**
     * check notifs
     */
    checkNotifs(): Observable<any> {
        return this.http.get(endpoint + 'notifications/check', httpOptions)
    }

    /**
     * check unreaded emails
     */
    checkEmails(role): Observable<any> {
        let data = {typeuser : role};
        return this.http.get(endpoint + 'messages/check', {params: data})
    }
    /**
     * set  emails as readed
     */
    setAsReaded(id,role): Observable<any> {
        let data = {typeuser : role, col: id};
        return this.http.put(endpoint + 'messages/'+id+'/readed',null,{params: data})
    }

    /**
     * set  notif as readed
     */
    setNotifAsReader(id): Observable<any> {
        let data = {id:id};
        return this.http.delete(endpoint + 'notifications/delete', {params: data})
    }


   /* getMessages(role , user): Observable<any> {
        let data = {t: role, u: user};
        return this.http.get<ChatEntity>(endpoint + 'messages/show' ,{params: data})
            .pipe(
                catchError(this.handleError('Chat ERROR'))
            );
    }*/
    getReportsByIntervenant(apprenant): Observable<any> {
        //let data = {user: apprenant};
        return this.http.get<any>(endpoint + 'intervenant/student/'+apprenant+'/reports' ,httpOptions)
            .pipe(
                catchError(this.handleError('User Error intervenant/students/ '))
            );
    }

    addNewMessage (chat): Observable<ChatEntity> {
        return this.http.put<ChatEntity>(endpoint + 'messages/new', chat, httpOptions)
            .pipe(
                catchError(this.handleError('newChat', chat))
            );
    }


    addContactMessage (contact): Observable<ContactEntity> {
        return this.http.put<ContactEntity>(endpoint + 'contact/new', contact, httpOptions)
            .pipe(
                catchError(this.handleError('add contact', contact))
            );
    }

    getNotes(id,id2,id3): Observable<any> {
        let data = {intervenant : id,subject: id2, apprenant: id3};
        return this.http.get<NotesEntity>(endpoint + 'apprenant/show/controls', {params: data})
            .pipe(
                catchError(this.handleError('ARROR USER CONTROLS'))
            );
    }



    getAllNotesApprenant(n1,n2): Observable<any> {

        let data = { apprenant: n1, year: n2};
        return this.http.get<NotesEntity>(endpoint + 'apprenant/show/all-controls', {params: data})
            .pipe(
                catchError(this.handleError('ARROR USER CONTROLS'))
            );
    }


    getBulletins(apr, int): Observable<any> {
        let data = {apprenant: apr, intervenant: int};
        return this.http.get<NotesEntity>(endpoint + 'bulletins/show', {params: data})
            .pipe(
                catchError(this.handleError('ARROR USER BULLETINS'))
            );
    }

    getMoy(sub,tri): Observable<any> {
        let data = {sub: sub, tri: tri};
        return this.http.get<any>(endpoint + 'apprenant/show/moy', {params: data})
            .pipe(
                catchError(this.handleError('ARROR USER moy'))
            );
    }
    getMoys(id,year): Observable<any> {
        let data = {year: year};
        return this.http.get<any>(endpoint + 'apprenant/'+id+'/moys', {params: data})
            .pipe(
                catchError(this.handleError('ARROR USER moy'))
            );
    }


    setMoy(moyenne): Observable<any> {
        //let data = {subject: sub, trimester: tri, apprenant: apprenant};
        return this.http.put<MoyenneEntity>(endpoint + 'moyenne/new', moyenne,httpOptions)
            .pipe(
                catchError(this.handleError('ARROR USER moy'))
            );
    }

    newNote (id, note): Observable<NotesEntity> {
        return this.http.put<NotesEntity>(endpoint + 'controls/new', note, httpOptions)
            .pipe(
                catchError(this.handleError('newSuivi', note))
            );
    }
    editNote (id, control): Observable<NotesEntity> {
        return this.http.post<NotesEntity>(endpoint + 'controls/'+id+'/edit', control)
            .pipe(
                catchError(this.handleError('editNote ERROR', id))
            );
    }
    updateUploadNote (id, control): Observable<NotesEntity> {
        let httpOptions1 = {
            headers: new HttpHeaders({
               // 'Content-Type':  'multipart/form-data',

            })
        };
        return this.http.post<NotesEntity>(endpoint + 'controls/update-file/' + id + '/control', control)
            .pipe(
                catchError(this.handleError('Edit Upload FAIL : ', control))
            );
    }

    newBulletin(data){
        return this.http.post<BulletinEntity>(endpoint + 'bulletins/new', data)
            .pipe(
                catchError(this.handleError('add bulletin Upload FAIL : ', data))
            );
    }


    getProfs(): Observable<any> {
        return this.http.get<ProfsEntity>(endpoint + 'apprenant/show/profs', httpOptions)
            .pipe(
                catchError(this.handleError('ARROR USER CONTROLS'))
            );
    }




    getTrimesters(): Observable<any> {
        return this.http.get<any>(endpoint + 'trimesters', httpOptions)
            .pipe(
                catchError(this.handleError('Trimester '))
            );
    }



    newReport (report): Observable<ReportEntity> {
        return this.http.put<ReportEntity>(endpoint + 'reports/new', report, httpOptions)
            .pipe(
                catchError(this.handleError('report', report))
            );
    }
    editReport (report): Observable<ReportEntity> {
        return this.http.put<ReportEntity>(endpoint + 'reports/edit', report, httpOptions)
            .pipe(
                catchError(this.handleError('report', report))
            );
    }

    getCities(): Observable<any> {
        return this.http.get<any>(endpoint + 'apprenant/show/cities', httpOptions)
            .pipe(
                catchError(this.handleError('ARROR USER Cities'))
            );
    }

    getAllSubjects(): Observable<any> {
        return this.http.get<any>(endpoint + 'apprenant/show/subjects', httpOptions)
            .pipe(
                catchError(this.handleError('ARROR USER Cities'))
            );
    }




    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            console.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}