import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from "@angular/material";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../../_services/user.service";
import {AlertsComponent} from "../alerts/alerts.component";


export interface DialogData {
    id:number
    trimester: number
    apprenant: number
    intervenant: number
}
@Component({
  selector: 'app-edit-upload-control-dialogue',
  templateUrl: './edit-upload-control-dialogue.component.html',
  styleUrls: ['./edit-upload-control-dialogue.component.scss'],
    providers: [
        UserService
    ]
})
export class EditUploadControlDialogueComponent implements OnInit {

  constructor(
      public dialogRef: MatDialogRef<EditUploadControlDialogueComponent>,
      @Inject(MAT_DIALOG_DATA) private data: DialogData,
      private formBuilder: FormBuilder,
      private userService: UserService,
      public snackBar: MatSnackBar
  ) { }
    controlUploadForm: FormGroup;
  id: number = this.data.id;

  selectedFile: FormData;

  ngOnInit() {

      this.controlUploadForm = this.formBuilder.group({
          upload: FileReader,
      });
  }

  onSubmit(){
      const formModel = this.controlUploadForm.value;
      //return;
      this.userService.updateUploadNote(this.id, this.selectedFile).subscribe(res => {
          this.snackBar.openFromComponent(AlertsComponent,{
              duration: 1000,
              panelClass: ['alerts-snackbar-seccus'],
              data:res
          });

          this.dialogRef.close();
      });

  }

    fileChange(event){
        this.selectedFile = event.target.files[0];

    }
    onFileChange(event) {
        let fileList: FileList = event.target.files;
        let formData:FormData;
        if(fileList.length > 0){
            let file: File = fileList[0];
            formData = new FormData();
            formData.append('uploadFile', file, file.name);
        }

        this.selectedFile = formData;

        //this.selectedFile = event.target.files[0];
        //this.controlUploadForm.get('upload').setValue(this.selectedFile );
        // let reader = new FileReader();
       /* if(event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];
            console.log(file);
            reader.readAsDataURL(file);

            reader.onload = () => {
                this.controlUploadForm.get('upload').setValue({
                    filename: file.name,
                    filetype: file.type,
                    fileexe:  file.name.match(/\.[0-9a-z]+$/i),
                    value: reader.result,
                });

            };
        }*/
    }

}
