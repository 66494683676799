export class ChatEntity {
    public id: number;
    public date_chat: Date;
    public content: string;
    public from_sender: string;
    public intervenant: number;
    public user?: number;
    public vu_intervenant?: number;
    public vu_apprenant?: number;
}
